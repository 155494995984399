import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from 'backend';
import {DialogComponent} from '../dialog/dialog.component';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})
export class WithdrawComponent implements OnInit {

  constructor(public authService: AuthService, public dialog: MatDialog) {
  }

  auth: any;

  withdrawForm = new FormGroup({
    amount: new FormControl(''),
  });

  amountInvalid = false;

  user;
  isLoading = false;
  errorMessage = '';
  successMessage = '';
  @ViewChild('closeModal') closeModal: ElementRef;

  ngOnInit(): void {
    this.authService.authStream.subscribe(auth => {
      this.auth = auth;
    });
  }

  openDialog(title, message, status){
    this.dialog.open(DialogComponent, {data: { message: message , title: title, status: status}})

  }
  onSubmit(): void {
    this.isLoading = true;
    if (this.validateAmount(this.withdrawForm.value.amount) === true) {
      this.authService.sendWithdraw(this.withdrawForm.value.amount, this.auth).subscribe(response => {
          console.log(response);
          this.errorMessage = '';
          this.successMessage = 'Success. Your withdrawal is being processed.';
          this.closeModal.nativeElement.click();
          this.openDialog("Success", 'Success. Your withdrawal is being processed.', 'success');
          this.isLoading = false;
        },
        error => {
          console.log(error.error.data);
          this.closeModal.nativeElement.click();
          this.openDialog('Oops!', error?.error.data, "error");
          this.isLoading = false;
          this.errorMessage = error.error.data;
        }
      );
    } else {
      this.isLoading = false;
      this.successMessage = '';
      this.errorMessage = 'Enter a valid amount';
    }
  }

  validateAmount(amount): boolean {
    if (amount > 19) {
      return true;
    } else {
      this.amountInvalid = true;
      return false;
    }
  }


}
