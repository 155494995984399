import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService, BetslipService} from 'backend';
import {Sms} from '../../models/Sms';
import {DialogComponent} from '../dialog/dialog.component';
import {JackpotBetslip} from '../../models/JackpotBetslip';
import {JackpotBet} from '../../models/JackpotBet';

@Component({
  selector: 'app-betslipjackpot',
  templateUrl: './betslipjackpot.component.html',
  styleUrls: ['./betslipjackpot.component.css']
})
export class BetslipjackpotComponent implements OnInit {
  constructor(private betslipService: BetslipService, public authService: AuthService, public dialog: MatDialog, public depositPlaceBetDialog: MatDialog,) {
  }

  @ViewChild('openLoginModal') openLoginModal: ElementRef;

  @Output() hideBetslipEvent = new EventEmitter<any>();
  auth: any;
  jackpotParams: any;
  activeJackpot: any;
  betslip: Array<JackpotBetslip> = [];
  betamount;

  stake = 0;
  _stake = 0;
  combinations = 1;
  total_matches = 0;
  jp_type;
  successMessage = '';
  errorMessage = '';
  isLoading;

  hideBetslip() {
    this.hideBetslipEvent.emit();
  }


  ngOnInit(): void {
    this.betslipService.jackpotBetslipStream.subscribe(betslip => {
      this.betslip = betslip;
    });

    this.betslipService.jackpotParamsStream.subscribe(params => {
      if (params != null) {
        this._stake = params.bet_amount;
      }
    });

    this.betslipService.jpStakeStream.subscribe(payload => {
      this.stake = payload.stake;
      this.combinations = payload.combinations;
    });


    this.authService.authStream.subscribe(auth => {
      this.auth = auth;
    });

    this.betslipService.activeJackpotType.subscribe(type => {
      this.jp_type = type
    });
  }

  toTwoDp(value) {
    return this.betslipService.toTwoDp(value);
  }

  clearSlip() {
    this.betslipService.clearJackpotSlip();
  }

  calculateStake() {
    let _combinations = 1;
    for (let i = 0; i < this.activeJackpot.jp_matches.length; i++) {
      const pos = i + 1;
      const matches = this.betslip.filter(i => i.position === pos);
      const match = this.betslip.find(match => match.position == pos);
      if (match != null && match != undefined) {
        const _selections = match.pick_key.toString().split(',');
        if (_selections.length > 1) {
          if (_combinations < 2) {
            _combinations = _combinations + 1;
          } else {
            _combinations = _combinations * 2;
          }
        }
      }
    }
    this.combinations = _combinations;
    this.stake = this._stake * this.combinations;
  }

  removeMatch(slip) {
    this.betslipService.removeJackpotMatch(slip);
  }

  getPick(selection, slip) {
    if (selection === slip.team1) {
      return '1';
    }
    if (selection === slip.team2) {
      return '2';
    }
    if (selection === 'draw') {
      return 'X';
    }

  }

  openDialog(title, message, status){
    if (message == '' || message == null || message == undefined) {
      message = 'Oops! Looks like something went wrong. Please try again.';
    }
    this.dialog.open(DialogComponent, {data: { message: message , title: title, status: status}})

  }

  placeBet() {
    const user = this.auth;
    console.log(user);
    if (user == null || user == undefined) {
      return this.openLoginModal.nativeElement.click();
    }
    this.isLoading = true;

    let _betString = '';
    let limit = this.betslip.length;
    limit = limit + 1;

    for (let i = 0; i < this.betslip.length; i++) {
      const position = i + 1;
      const match = this.betslip.find(i => i.position === position);
      let _selection = '';
      const _selections = match.pick_key.toString().split(',');
      for (let m = 0; m < _selections.length; m++) {
        console.log('i is: ' + m + 'and limit is: ' + limit);
        // this.betslip[i]
        const check = _selections[m];
        const pick = this.getPick(check, match);
        _selection = _selection.toString() + pick;
      }
      if (_betString == '') {
        _betString = _betString + _selection;
      } else {
        _betString = _betString + '#' + _selection;
      }
    }

    console.log('am over here');
    this.isLoading = true;
    const slips = this.betslip;
    const betObject = new JackpotBet();
    betObject.app_name = 'web_ui';
    betObject.bet_string = _betString;
    betObject.profile_id = user.profile_id;
    betObject.stake_amount = this.stake;
    betObject.slip = this.betslip;
    betObject.type = 'JACKPOT';
    betObject.combi = 1;
    betObject.jp_type = this.jp_type;

    console.log(betObject);

    const response = this.betslipService.placeJackpotBet(betObject, user);

    response.subscribe((response: { res: any, description: any }) => {
        console.log(response);


        if (response.res == 0) {
          this.openDialog("Success", response.description, "success");

          this.errorMessage = '';
          this.clearSlip();
          this.isLoading = false;
          const sms = new Sms();
          sms.recipients = user.username;
          sms.message = response.description;
          sms.insertDb = true;
          this.authService.sendSMS(sms).subscribe(res => {
              // console.log(response);
            },
            err => console.log('HTTP Error', err),
          );
        } else {
          this.isLoading = false;
          this.openDialog("Oops!", response.description, "error");
        }

      },
      err => {
        this.openDialog("Error", err?.error, "error");

        this.isLoading = false;
      });

  }


  onKey(event: any) { // without type info
    // console.log(event.target.value);
    // console.log(this.stake.value)
    this.betamount = this.betslipService.getBetAmount(this.stake);

  }

  myFunc(total, num) {
    return total - num;
  }

  closeAlert() {
    this.successMessage = '';
    this.errorMessage = '';
  }

}
