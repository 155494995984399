import { Component, Input, OnInit } from '@angular/core';
import { BackendService } from 'backend';
import { BetslipService } from 'backend';
import { Betslip } from 'projects/desktop/src/app/models/Betslip';

@Component({
  selector: 'app-gamecard',
  templateUrl: './gamecard.component.html',
  styleUrls: ['./gamecard.component.css']
})
export class GamecardComponent implements OnInit {

  constructor(private backendService: BackendService, private betslipService: BetslipService) { }
  @Input() match:any;
  @Input() currentSport:any;
  betslip: Betslip[] = [];
  ngOnInit(): void {
    this.betslipService.betslipStream.subscribe(betslip => {
      this.betslip = betslip;
    })
  }

  getTime(date){
    return this.backendService.getTime(date);
  }

  getDateWell(date_){
    var arr = date_.split(/[- :]/);
    var date = new Date(Number(arr[0]), Number(arr[1])-1, Number(arr[2]), Number(arr[3]), Number(arr[4]), Number(arr[5]));
    return date;
  }

  actOnButton(match, pick, sub_type_id, odd_type, odd_value, odd_id, parent_match_id)
  {
    // console.log(slip);
    var slip = new Betslip();
    slip.matchId = match.match_id.toString();
    slip.homeTeam = match.home_team;
    slip.awayTeam = match.away_team;
    slip.betType = 'prematch';
    slip.oddType = odd_type;
    slip.odd_value = odd_value;
    slip.parent_match_id = match.parent_match_id;
    slip.pick_key = pick;
    slip.sub_type_id = sub_type_id;
    slip.event_odd_id = odd_id;

    console.log(slip);

    var betslip = this.betslipService.addToBetslip(slip);
    this.betslipService.betslipStream.next(betslip);

  }

  inSlip(match_id, sub_type_id, pick_key){
    if(this.betslip != null && this.betslip != undefined){
      let check = this.betslip.find(i => i.matchId === match_id);
      if(check != undefined){
        if(check.sub_type_id == sub_type_id && check.pick_key == pick_key){
          return true;
        }
      }
    }
    
    return false;
  }
}
