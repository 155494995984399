<div class="main-page-wrapper mobile-app-banner">
  <div class="container py-5 ">

      <div class="row justify-content-center align-items-center margin-auto ">
        <div class="col-md-4 mb-5 mt-5" style="text-align: center;">
         
          <a class="app-buttons" href="/assets/mobile_app/undabet.apk" style="padding: 1rem 2rem; color: white; font-size: 18px;"> <img class="app-img" src="assets/img/android.png" alt="" style="height: 100px; margin-top: -10px; margin-right: 10px;">  Android</a>
          
        </div>

        <div class="col-md-4 mb-5 mt-5" style="text-align: center;">
          <a class="app-buttons" href="https://apps.apple.com/us/app/undabet/id1533773064" style="padding: 1rem 2rem; color: white; font-size: 18px;"> <img class="app-img" src="assets/img/ios.png" alt="" style="height: 100px; margin-top: -10px; margin-right: 10px;"> iPhone</a>
        
        </div>  
      </div>
  </div>
</div>
  