import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AccountComponent} from './pages/account/account.component';
import {BetdetailComponent} from './pages/betdetail/betdetail.component';
import {BetsComponent} from './pages/bets/bets.component';
import {HomeComponent} from './pages/home/home.component';
import {MarketsComponent} from './pages/markets/markets.component';
import {TransactionsComponent} from './pages/transactions/transactions.component';
import {CountriesComponent} from './pages/countries/countries.component';
import {JackpotsComponent} from './pages/jackpots/jackpots.component';
import {AuthGuard} from './guard/auth.guard';
import { BonusComponent } from './components/bonus/bonus.component';
import { MobileappComponent } from './components/mobileapp/mobileapp.component';
import { HelpComponent } from './components/help/help.component';
import { VirtualsComponent } from './pages/virtuals/virtuals.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ResponsiblegamblingComponent } from './pages/responsiblegambling/responsiblegambling.component';
import { CasinoComponent } from './pages/casino/casino.component';
import { LivecasinoComponent } from './pages/livecasino/livecasino.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full', data: {esport: 0}},
  {path: 'markets', component: MarketsComponent, pathMatch: 'full'},
  {path: 'account', component: AccountComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'bets', component: BetsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'transactions', component: TransactionsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'betdetail', component: BetdetailComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'countries', component: CountriesComponent, pathMatch: 'full'},
  {path: 'jackpots', component: JackpotsComponent, pathMatch: 'full'},
  {path: 'bonus', component: BonusComponent, pathMatch: 'full'},
  {path: 'app', component: MobileappComponent, pathMatch: 'full'},
  {path: 'how-to-play', component: HelpComponent, pathMatch: 'full'},
  {path: 'virtuals', component: VirtualsComponent, pathMatch: 'full'},
  {path: 'terms-and-conditions', component: TermsComponent, pathMatch: 'full'},
  {path: 'responsible-gambling', component: ResponsiblegamblingComponent, pathMatch: 'full'},
  {path: 'e-sports', component: HomeComponent, pathMatch: 'full', data: {esport: 1}},
  {path: 'virtuals', component: VirtualsComponent, pathMatch: 'full'},
  {path: 'casino', component: CasinoComponent, pathMatch: 'full'},
  {path: 'live-casino', component: LivecasinoComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
