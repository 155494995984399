<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
      <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
        
      </div>
      <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
        <div class="topbar d-flex justify-content-left">
            
            <div class="text-light txt ">
               Betfiti Bonuses 
            </div>

           
        </div>
        <div class="">
          <img src="/assets/banners/BONUS.png" class="d-block w-100" alt="Betfiti 100% deposit bonus">
            
        </div>
        <div class="txt-bonus px-2 py-2">
          <p><strong>Betfiti awards you KES 2,000.00 welcoming bonus to be redeemed in bits.</strong></p>
                <p> <i class="fa fa-check-circle mr-2" ></i> Register first, make a deposit of at least 20/= to get the bonus.</p>
                <p> <i class="fa fa-check-circle mr-2" ></i> <strong>T&C:</strong>  You  can <strong>NOT</strong> bet/withdraw bonus. Bonus will be refunding you 10% of every stake you use if you place a bet with a total odds of <strong>10 or above.</strong> </p>
                <p> <i class="fa fa-check-circle mr-2" ></i>The 10% amount refunded into your account from bonus will be in terms of cash therefore you can withdraw or bet with it if it is 50 or above.</p>
                <p> <i class="fa fa-check-circle mr-2" ></i>The 10% refund is automatically credited to your account immediately after you have bet therefore you will always get it regardless of your bet status.</p>
                <p> <i class="fa fa-check-circle mr-2" ></i>EXAMPLE: if you place your bet with a stake of 1000/=, you will be refunded back 100/= from bonus remaining with 1900 Bonus balance.</p>
                <p> <i class="fa fa-check-circle mr-2" ></i>This offer is valid until you exhaust your Bonus.</p>
        </div>
        
        
      </div>
      <div class="w-20 shadow-sm righ-side bg-light hide-mobile">
       
      </div>
    </div>
  
  </section>
  