
<section class="container-fluid py-2">
    
    <div class="row-wrapper d-flex justify-content-around">
        <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
            <app-sidebar></app-sidebar>
        </div>
        <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
            <div class="topbar d-flex justify-content-left">
                <div class="icon hover-pointer" (click)="goback()">
                    <i class="bi bi-arrow-left text-light"></i>
                </div>
                <div class="text-light txt ">
                   Match Details
                </div>
            </div>
            <section class="markets-section py-1">
                <div class="game-details text-center" *ngIf="match != undefined">
                    <div class="markets-league mb-1">
                        {{ match.category }} - {{ match.league }} 
                    </div>
                    
                    <div class="home-team mb-1">
                        {{ match.home_team }} <span class="text-muted">V</span> 
                        {{ match.away_team }}
                    </div>

                    <div class="markets-time">
                        {{ match.date }} {{ match.start_time }}
                    </div>
                    
                </div>
            
                <mat-accordion>
                    <!-- start expansion panel -->
                    <mat-expansion-panel [expanded]="market.key == '1x2'" *ngFor="let market of markets | keyvalue : returnZero">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ market.key }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="odd-section " >
                        <!-- start button -->
                        <button class="btn btn-odds " [class.third-btn]="market.key == '1x2'" [class.half-btn]="market.key != '1x2'"  [class.picked]="inSlip(odd.match_id.toString(), odd.sub_type_id.toString(), odd.odd_key)" *ngFor="let odd of market.value" id="{{ odd.match_id.toString() + odd.sub_type_id.toString() + odd.odd_key }}" (click)="actOnButton(odd, odd.odd_key, toString(odd.sub_type_id), odd.name, odd.odd_value, odd.odd_id, odd.parent_match_id)" >
                            <div class="market-key">
                                {{ odd.display }}
                            </div>
                            <div class="market-value">
                                {{ odd.odd_value }}
                            </div>
                        </button>
                        <!-- end button -->
                       
                      </div>
                    </mat-expansion-panel>
                     <!-- end expansion panel -->
                     
                    
                   
                  </mat-accordion>
            </section>
            
        </div>
        <div class="w-20 shadow-sm righ-side bg-light hide-mobile sticky">
            <app-betslip></app-betslip>
        </div>
    </div>
    
</section>