import {Component, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from 'backend';
import {BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {LocalStorageServiceService} from 'backend';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';

@Component({
  selector: 'app-headermobile',
  templateUrl: './headermobile.component.html',
  styleUrls: ['./headermobile.component.css']
})
export class HeadermobileComponent implements  OnInit, OnDestroy  {

  private readonly _onDestroy: Subject<void> = new Subject<void>();

  activePage: BehaviorSubject<string> = new BehaviorSubject<string>('/');

  constructor(public authService: AuthService, private localStorage: LocalStorageServiceService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.authService.authStream.pipe(takeUntil(this._onDestroy)).subscribe(auth => {
      if (auth === null || auth === undefined) {
        this.localStorage.clearAuthFromStorage();
      }
    });
    this.router.events
      .pipe(
        takeUntil(this._onDestroy),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: RouterEvent) => {
        this.activePage.next(event.url);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

}
