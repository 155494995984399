<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="border: 0px;">
        <h5 class="modal-title" id="depositLabel">Share Betslip</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
          <div class="text-center" style="font-weight: 300; font-size: 10px;">Your Betfiti Booking Code</div>
          <h1 class="text-center mb-3" style="font-weight: 700;">{{ code }}</h1>

          <div class="text-success text-center">{{ successMessage }}</div>
          <div class="input-wrapper px-5">
            <input class="url" value="{{ url }}" #copyText style="margin: auto; width: 100%; background-color: rgb(255,255,255); border-radius: 2px; border:0px; padding: 5px; text-align: center;">
          </div>
            
          <div class="icons-wrapper d-flex justify-content-center">
            <div class="iconnn p-2">
                <a href="{{ whatsappUrl }}"><img src="/assets/img/whatsapp.png" class="shareicon" alt="facebook icon"></a>
                
                <div>
                    whatsapp
                </div>
            </div>
            <div class="iconnn p-2">
                <a href="{{ telegramUrl }}"><img src="/assets/img/telegram.png" class="shareicon" alt="facebook icon"></a>
                <div>
                    telegram
                </div>
                
            </div>
            <div class="iconnn p-2">
                <a href="{{ facebookUrl }}"><img src="/assets/img/facebook.png" class="shareicon" alt="facebook icon"></a>
                <div>
                    facebook
                </div>
                
            </div>
            <div class="iconnn p-2">
                <a href="{{ twitterUrl }}"><img src="/assets/img/twitter.png" class="shareicon" alt="facebook icon"></a>
                <div>
                    twitter
                </div>
                
            </div>
            
            <div class="iconnn p-2">
                <img src="/assets/img/link.png" class="shareicon hover-pointer" (click)="copyCode(copyText)" alt="facebook icon">
                <div>
                    Copy Link
                </div> 
            </div>
          </div>
      </div>

    </div>
</div>
