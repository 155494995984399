import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SportsService } from 'backend';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  constructor(private sportsService: SportsService) { }

  ngOnInit(): void {
    
  }
  

  searchForm = new FormGroup({
    filter: new FormControl(''),
  });
  search(){
    this.sportsService.matchesStream.next([]);
    this.sportsService.gamesLoading.next(true);
    var currentSport = this.sportsService.currentSportStream.value;
    var filter = this.searchForm.value.filter;
    console.log(filter);
    this.sportsService.search(currentSport.type, currentSport.sport_id, filter, 0).subscribe(payload => {
      console.log(payload.result);
      this.sportsService.matchesStream.next(payload.result);
      this.sportsService.gamesLoading.next(false);

    }, err =>{
      console.log(err);
      this.sportsService.gamesLoading.next(false);
    })


  }

}
