<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Get Started </h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <button #openLoginModal type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdrop" hidden></button>
        <button #openFinishRegistrationModal type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdropFinishRegistration" hidden></button>
      </div>
      <div class="modal-body">
        <div class="text-danger" *ngIf="errorMessage != ''">
          {{ errorMessage }}
        </div>
          <form [formGroup]="registerForm">
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input formControlName="phone" type="text" [class.is-invalid]="phoneInValidVar" class="form-control" >
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input formControlName="password" type="password" class="form-control" [class.is-invalid]="passwordInValidVar">
              </div>
              <div class="mb-3">
                  <label for="password" class="form-label">Confirm Password</label>
                  <input formControlName="password2" type="password" [class.is-invalid]="passwordInValidVar" class="form-control">
                </div>
                <div class="mb-3 form-check">
                  <input type="checkbox" class="form-check-input" required>
                  <label class="form-check-label" >I am at least 18 years of age and I have read, accept and agree to the Terms and Conditions.</label>
                </div>
             
              <button (click)="onSubmit()" type="submit" class="btn btn-success form-control">Register</button>

              <div class="forgot-pass py-1 mb-2 mt-2 hover-pointer" (click)="login()">
                  Already have an account? Login Here
              </div>
            </form>
      </div>
      
    </div>
  </div>