import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {AuthService} from 'backend';
import {MyTrx} from 'backend';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit, OnDestroy {

  private readonly _onDestroy: Subject<void> = new Subject<void>();

  transactions: BehaviorSubject<MyTrx[]> = new BehaviorSubject<MyTrx[]>([]);
  transactionsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private deposits: MyTrx[] = [];
  private withdrawals: MyTrx[] = [];
  currentTab: BehaviorSubject<'deposit' | 'withdrawal'> = new BehaviorSubject<'deposit' | 'withdrawal'>('deposit');

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.deposits = [];
    this.withdrawals = [];
    this.transactionsLoading.next(true);
    this.authService.getTransactions(this.authService.authStream.value).pipe(takeUntil(this._onDestroy))
      .subscribe(t => {
        this.deposits = t.filter((trx: MyTrx) => {
          return (trx.type.toLowerCase() === 'deposit');
        });
        this.withdrawals = t.filter((trx: MyTrx) => {
          return (trx.type.toLowerCase() === 'withdrawal');
        });
        if (this.transactions.value.length <= 0) {
          this.transaction('deposit');
        }
        this.transactionsLoading.next(false);
      }, error => {
        this.transactionsLoading.next(false);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  transaction(type: 'deposit' | 'withdrawal'): void {
    this.transactions.next(type === 'deposit' ? this.deposits : type === 'withdrawal' ? this.withdrawals : []);
  }

  status(trx: MyTrx): string {
    let _s = trx.status;
    const _r = trx.reference !== null && trx.reference !== undefined ? trx.reference : 'N/A';
    if (trx.status === 'TRX_SUCCESS') {
      _s = 'PENDING';
    }
    return `${_s} - ${_r}`;
  }

  date(date?: string): string {
    if (date !== null && date !== undefined) {
      return date.replace('T', ' ');
    } else {
      return '';
    }
  }
}
