import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-deposit-and-place-bet',
  templateUrl: './deposit-and-place-bet.component.html',
  styleUrls: ['./deposit-and-place-bet.component.css']
})
export class DepositAndPlaceBetComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {message: string}) { }

  ngOnInit(): void {
  }

}
