import { Component, Input, OnInit } from '@angular/core';
import { SportsService, BetslipService } from 'backend';
import { JackpotBetslip } from '../../models/JackpotBetslip';

@Component({
  selector: 'app-jackpot',
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.css']
})
export class JackpotComponent implements OnInit {

  constructor(public sportsService: SportsService, private betslipService: BetslipService) { }
  @Input() jackpot_id: number;
  jp_matches: any[];
  jackpotParams: any;
  description = '';
  jackpots: any[] = [];
  ngOnInit(): void {
    this.betslipService.jackpotsStream.subscribe(jackpots => {
      this.jackpots = jackpots;
    });
    this.sportsService.jackpotLoading.next(true);
    this.sportsService.fetchActiveJackpot(this.jackpot_id).subscribe(payload => {
      this.jackpotParams = payload.jackpot_params;
      console.log(payload);
      if (payload.jackpot_params.active === 1 || payload.jackpot_params.active === 2){
        this.jp_matches = payload.jp_matches;
        this.jackpots.push(payload);
        console.log(this.jackpots);
        this.betslipService.jackpotsStream.next(this.jackpots);

      }else{
        this.description = this.jackpotParams.description;
      }
      this.sportsService.jackpotLoading.next(false);
    }, error => {
      this.sportsService.jackpotLoading.next(false);
    });
  }

  autopick(): void {
    let _betslip = [];
    this.betslipService.clearJackpotSlip();
    this.betslipService.activeJackpotType.next(this.jackpotParams.jp_type);
    for (let index = 0; index < this.jp_matches.length; index++) {
      if (index >= 0) {
        const match = this.jp_matches[index];
        const picks = [match.team1, 'draw', match.team2];
        const _selection = Math.floor(Math.random() * 3);
        const selection = picks[_selection];

        const slip = new JackpotBetslip();
        slip.jp_match_id = match.jp_match_id;
        slip.team1 = match.team1;
        slip.team2 = match.team2;
        slip.betType = 'jackpot';
        slip.oddType = '3way';
        slip.odd_value = selection === match.team1 ? match.home_odd : selection === match.team2 ? match.away_odd : match.neutral_odd;
        slip.parent_match_id = match.parent_match_id;
        slip.pick_key = selection;
        slip.position = match.position;
        slip.combi = match.jp_match_id.toString() + match.position.toString() + selection.toString();

        _betslip = this.betslipService.addToJackpotBetslip(slip, this.jackpotParams);
      }
    }

    this.betslipService.jackpotBetslipStream.next(_betslip);
  }

}
