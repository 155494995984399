<section class="container-fluid py-2">
  <div class="row-wrapper d-flex justify-content-around">
    <div class="w-15 lef-sec shadow-sm righ-side hide-mobile bg-light">
      
    </div>
    <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">

      <app-account-details></app-account-details>

      <section style="background-color: #fff;">
        <div class="topbar d-flex justify-content-left">
          <div class="icon " [routerLink]="['/account']">
            <i class="bi bi-arrow-left text-light"></i>
          </div>
          <div class="text-light txt ">
            Transactions
          </div>
        </div>

        <div class=" pt-2" >
          <div class="tabbar d-flex justify-content-center">
            <div class="highlights" [class.active]="(currentTab | async) === 'deposit'" (click)="currentTab.next('deposit'); transaction('deposit');">
              Deposit
            </div>
            <div class="highlights" [class.active]="(currentTab | async) === 'withdrawal'" (click)="currentTab.next('withdrawal'); transaction('withdrawal');">
              Withdrawal
            </div>
          </div>
        </div>

        <app-shimmer [count]="5" [type]="'transaction'" *ngIf="(transactionsLoading | async)"></app-shimmer>

        <div class="trans-wrapper" *ngIf="!(transactionsLoading | async)">
          <div class="item-div d-flex justify-content-between" *ngFor="let trx of (transactions | async)">
            <div class="left">
              <span class="title"> {{ status(trx) }} </span> <br>
              <span class="date text-muted">{{ date(trx.trans_time) }}</span>
            </div>

            <div class="right text-success pt-1">
              {{ trx.amount }}
            </div>
          </div>
        </div>
      </section>

    </div>
    <div class="w-20 shadow-sm righ-side hide-mobile bg-light">
      
    </div>
  </div>

</section>
