<section class="betslip">
    <button #openLoginModal class="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Log In</button>
    <div class="header-betslip-sec text-center d-flex justify-content-between">
        <div class="leff">
            <span class="big-poppi">{{ betslip.length }}</span> Betslip
        </div>
        <div class="cent text-center hide-desktop" style="font-size: 20px;" (click)="hideBetslip()">
            <i class="bi bi-chevron-compact-down"></i>
        </div>
        <div class="rii" *ngIf="(authService.authStream | async)">
            KES {{ (authService.authStream | async)?.balance }}
        </div>
    </div>
    <div class="matchessec" >
        <!-- Begin Match -->
        <div class="match-wrapper" *ngFor="let slip of betslip">
            <div class=" d-flex justify-content-left">
                <div class="rem-sec py-3 w-20 text-center" (click)="removeMatch(slip)">
                    <i class="bi bi-x-lg"></i>
                </div>
                <div class="det-sec w-80">
                    <div class="row1 d-flex justify-content-between">
                        <div class="leff">
                            {{ slip.pick_key }}
                        </div>
                        <div class="righh">
                            {{ slip.odd_value }}
                        </div>
                    </div>

                    <div class="match-detsec">
                        {{ slip.matchId }} {{ slip.team1 }} <span class="text-muted">v</span>  {{ slip.team2 }}
                    </div>
                    <div class="subtype-sec">
                        <span class="text-muted">type: </span> {{ slip.oddType }}
                    </div>
                </div>
            </div>
        </div>
        <!-- End match -->

    </div>
    <div class="mathsec" style="padding-top: 10px;">
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Total Stake (KES)
            </div>
            <div class="form-sec">
                <form >
                    <input disabled type="number" class="stake-input" placeholder="KES 49" value="{{ stake }}">
                </form>
            </div>
        </div>
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Combinations
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ combinations }}</span>
            </div>
        </div>


    </div>
    <div class="submission d-flex justify-content-center" style="width: 100%;">
        <div class="w-50">
            <button class="btn btn-remove-all btn-danger btn-sm form-control" (click)="clearSlip()">
                <i class="bi bi-trash"></i> Remove All
            </button>
        </div>
        <div class="w-50">
            <button (click)="placeBet()" [disabled]="isLoading"  class="btn btn-remove-all btn-success btn-sm form-control">
                <i class="bi bi-check2"></i> Place Bet
            </button>
        </div>
    </div>
</section>
