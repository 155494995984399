import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'backend';
import { Sms } from '../../models/Sms';
import {LocalStorageServiceService} from 'backend';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef
  @ViewChild('openForgotPassModal') openForgotPassModal: ElementRef
  @ViewChild('openRegisterModal') openRegisterModal: ElementRef
  @ViewChild('openFinishRegistrationModal') openFinishRegistrationModal: ElementRef
  constructor(private authService: AuthService, private localStorageService: LocalStorageServiceService, private router: Router) { }

  ngOnInit(): void {
  }

  phoneInValidVar = false;
  passwordInValidVar = false;

  next = '';


  loginForm = new FormGroup({
    phone: new FormControl(''),
    password: new FormControl(''),
    remember: new FormControl(''),
  });

  forgotPass(){
    this.openForgotPassModal.nativeElement.click();
    this.closeModal.nativeElement.click();
  }

  register(){
    this.openRegisterModal.nativeElement.click();
    this.closeModal.nativeElement.click();
  }

  phoneValid(phone){
    var pattern1 = /^(?:\+?(?:[1-9]{3})|0)?1([0-9]{8})$/;
    var pattern2 = /^(?:\+?(?:[1-9]{3})|0)?7([0-9]{8})$/;
    if(phone == null || phone == ""){
      this.phoneInValidVar = true;
      return false;
    }
    if( phone.match(pattern1) ){
      this.phoneInValidVar = false;
      return true;
    }
    if( phone.match(pattern2) ){
      this.phoneInValidVar = false;
      return true;
    }
    this.phoneInValidVar = true;
    return false;
  }

  passwordValid(password){
    if(password == "" || password == null){
      this.passwordInValidVar = true;
      return false;
    }else{
      this.passwordInValidVar = false;
      return true
    }
  }

  isLoading = false;
  isAuthenticated = false;
  user = null;
  errorMessage = '';

  loginUser(loginForm){
    this.isLoading = true;
    this.errorMessage = '';
    var phone = loginForm.value.phone;
    var password = loginForm.value.password;
    var remember = loginForm.value.remember;
    phone = '254' + phone.substring(phone.length-9, phone.length);
    this.authService.loginUser(phone, password, remember).subscribe(response => {
      console.log(response);
      if(response.responseCode == 0){
        var auth = this.authService.getProfileObject(response);
        this.localStorageService.setAuthToStorage(auth);
        this.authService.authStream.next(auth);
        this.closeModal.nativeElement.click();
        this.isLoading = false;
      }else if(response.responseCode == 101){
        var msg = "Code: " + response.responseDescription;
        var sms = new Sms();
        sms.recipients = phone;
        sms.message = msg;
        sms.insertDb = true;


        // this.authService.sendSMS(sms).subscribe(res => {
        //   console.log(response);
          
        // },
        // err => console.log('HTTP Error: ', err),);
        this.isLoading = false;
        this.openFinishRegistrationModal.nativeElement.click();
        this.closeModal.nativeElement.click();
        // this.router.navigate(['/confirm-registration/' + phone]);
      }else{
        this.errorMessage = response.responseDescription;
        this.phoneInValidVar = true;
        this.passwordInValidVar = true;
        this.isLoading = false;
        
      }
    },
    error => {
      console.log(error);
    })
  }

  onSubmit(){
    if(this.phoneValid(this.loginForm.value.phone) && this.passwordValid(this.loginForm.value.password)){
      console.log("phone is valid")
      this.isLoading = true;
      this.loginUser(this.loginForm);
    }else{
      console.log("phone invalid")
    }
  }

}
