<section class="jackpot">
  <div style="width: 100%;" class="text-center" *ngIf="description != ''">{{ description }}</div>
  <div class="autopick row" *ngIf="jackpotParams != null && (jackpotParams.active == 1 || jackpotParams.active == 2)">
    <div class="col-md-6 px-5 text-center txt">
      Use the autopick feature to quickly populate your jackpot betslip
    </div>
    <div class="col-md-6">
      <button [disabled]="jackpotParams.active != 1" class="btn btn-secondary-color form-control" (click)="autopick()"><i class="stars bi bi-stars"></i> Autopick</button>
    </div>
  </div>

  <app-shimmer [count]="5" [type]="'jackpot'" *ngIf="(sportsService.jackpotLoading | async)"></app-shimmer>

  <ng-container *ngIf="!(sportsService.jackpotLoading | async)">
    <app-gamecardjackpot *ngFor="let match of jp_matches" [match]="match" [jackpotParams]="jackpotParams"></app-gamecardjackpot>
  </ng-container>
</section>
