<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="depositLabel">Deposit via MPESA PB: 47 79 79</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="error text-danger" *ngIf="errorMessage != ''">
          {{ errorMessage }}
        </div>
        <div class="error text-success" *ngIf="successMessage != ''">
          {{ successMessage }}
        </div>
          <div class="">
              <div class="ball text-muted">
                  Balance (KES) {{ (authService.authStream | async)?.balance }}
              </div>
              <div class="ball ">
                  Minimum Deposit (KES) 20.00
              </div>
          </div>
          <form [formGroup]="depositForm">
              <div class="mb-3 mt-3">
                <input type="number" [class.is-invalid]="amountInvalid" formControlName="amount" class="form-control" placeholder="Amount to deposit">
              </div>
              <button type="submit" (click)="onSubmit()" [disabled]="isLoading" class="btn btn-success form-control">Deposit</button>
            </form>
      </div>

    </div>
</div>
