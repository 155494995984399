<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
      <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
        
      </div>
      <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
        <div class="topbar d-flex justify-content-left">
           
            <div class="text-light txt ">
               How to play 
            </div>

           
        </div>
        <div class="">
          <img src="/assets/banners/welcome.png" class="d-block w-100" alt="Betfiti mobile app">

            <div class="px-2 py-2">
              <p align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
              text-align:center'><strong><span lang=EN-GB style='mso-ansi-language:
              EN-GB'>UNDABET HELP GUIDE</span></strong></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><strong><span
              style='font-size:10.0pt'>Age</span></strong><span style='
              font-family:"Times New Roman",serif'>: You must be over 18 years of age to play
              on <span class=SpellE>Betfiti</span>. All the terms and conditions provided in
              the <strong>TERMS PAGE apply.</strong></span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >Via SMS, send SMS
              'REGISTER' to <strong>29008</strong>&nbsp;or via web, log on to
              https://www.betfiti.ke/&nbsp;and create an account via the Register Now link.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><strong><span
              style='font-size:10.0pt'>Deposit: You can deposit any amount up to KSH 70,000
              from PAYBILL 47 79 79, Account <span class=SpellE>Betfiti.<span style='font-weight:
              normal'>Once</span></span></span></strong><span style='
              font-family:"Times New Roman",serif'> you have an account with <span
              class=SpellE>Betfiti</span></span><span lang=EN-GB style='
              font-family:"Times New Roman",serif;mso-ansi-language:EN-GB'>.</span><span
              > You may deposit
              via the deposit link on </span><span style='font-family:"Times New Roman",serif;
              mso-bidi-theme-font:minor-bidi'><a href="https://www.betfiti.ke/">https://www.betfiti.ke/</a></span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><strong><span
              style='font-size:10.0pt'>While logged in, the balance will be displayed in the
              top right corner of the web <span class=SpellE><span class=GramE>page.<span
              style='font-weight:normal'>Via</span></span></span></span></strong><span
              > SMS, send the
              word BALANCE to <strong>29008</strong></span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><strong><span
              style='font-size:10.0pt'>Bet</span></strong><span style='
              font-family:"Times New Roman",serif'>: You may bet via any channel provided by <span
              class=SpellE>Betfiti</span>, including <b>WEB</b>, <b>USSD</b>, <b>SMS</b>, or
              the <span class=SpellE><b>Betfiti</b></span><b> App</b>.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >You may subscribe
              to receive SMS with upcoming games by sending the word &quot;football&quot; to <strong>29008</strong>.
              Once you receive the games, follow the example provided to place a bet via SMS.
              ID # Pick # STAKE is the general format.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >The games will
              also be listed on https://www.betfiti.ke/,&nbsp;from where you can visit and bet
              on-line.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >Betting shortcuts</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >TO OBTAIN TOP
              Matches Send SMS&nbsp;<strong>'GAMES'</strong>&nbsp;to <strong>29008</strong></span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >ID # PICK #
              AMOUNT; 3 WAY, SINGLE MATCH</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >Multiple matches
              in three ways; ID1 # PICK1 # ID2 # PICK2 # ID3 # PICK3 #... # STAKE</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >Both teams must
              score; use the prefixes GG (True) and NG (False).</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >Totals Over &amp;
              Under; Prefix ov25 or ov25-for over 2.5 and un25 or u25 for under 2.5.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><strong><span
              style='font-size:10.0pt'>Where there is a playing prefix, such as in the
              example above, create your SMS as ID # PREFIX # STAKE, for example, 123# GG #
              50 or 123# NG # 50 to bet on both teams scoring True or False.</span></strong><span
              ></span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><strong><span
              style='font-size:10.0pt'>Winners</span></strong><span style='
              font-family:"Times New Roman",serif'>: All winnings will be deposited into
              your&nbsp;<span class=SpellE>Betfiti</span> account and the user will be
              notified of their winnings. Users are free to access their winnings via all
              channels provided by <span class=SpellE>Betfiti</span>, including SMS, Web,
              USSD, and <span class=SpellE>Betfiti</span> App.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >SMS W # AMOUNT to
              29008 to make a withdrawal</span><span lang=EN-GB style='
              font-family:"Times New Roman",serif;mso-ansi-language:EN-GB'> a </span><span
              >link is also
              available on </span><span style='font-family:"Times New Roman",serif;
              mso-bidi-theme-font:minor-bidi'>https://www.betfiti.ke/</span><span
              > for on-line
              withdrawals. Note: Respective withdrawal charges apply.</span></p>
              
              <p style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
              >&nbsp;</span></p>
              
              <p class=MsoNormal><span >&nbsp;</span></p>
            </div>
        </div>
        
        
      </div>
      <div class="w-20 shadow-sm righ-side bg-light hide-mobile">
       
      </div>
    </div>
  
  </section>
  