<section class="d-flex justify-content-left shadow-sm">
    
    <div class="righhhty" style="width: 25%;">
        <button (click)="search()" class="btn btn-primary-color form-control" style="font-size: 13px; line-height: 1; color: #fff; border-radius: 0!important;"> <i class="bi bi-search"></i> Search</button>
    </div>
    <div class="lefffy" style="width: 75%;">
        <form [formGroup]="searchForm" (submit)="search()">
            <input formControlName="filter" type="text" class="form-control" style="font-size: 12px; line-height: 1; border:0px; border-radius: 0!important;" placeholder="Search...">
       
        </form>
    </div>
    
</section>