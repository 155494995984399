<section style="background-color: #1B1E25">
    
<div class="footer-section">
    <div class="paybills-wrapper">
       
         <div class="paybill">
            M-PESA Paybill: <br>
            <span style="color: rgba(255,255,255, .7); font-weight: 700; font-size:24px">47 79 79</span> 
        </div>
        <div class="paybill">
            Dial Now: <br>
            <span style="color: rgba(255,255,255, .7); font-weight: 700; font-size:24px">*000#</span> 
        </div>
         <div class="paybill">
            <div>Social Media: </div>
            <div class="footer-icons-wrapper d-flex justify-content-center">
               
                
                <div class="iconnn ">
                    <a target="_blank" href="https://www.facebook.com/Betfiti.ke/"><img src="/assets/img/facebook.png" class="footericon" alt="facebook icon"></a>
                    
                    
                </div>
                <div class="iconnn">
                    <a target="_blank" href="https://twitter.com/UshindiBetke"><img src="/assets/img/twitter.png" class="footericon" alt="facebook icon"></a>
                    
                </div>
                <div class="iconnn ">
                    <a target="_blank" href="https://www.instagram.com/betfiti.ke/"><img src="/assets/img/instagram.png" class="footericon" alt="facebook icon"></a>
                    
                </div>
                
                
              </div>
           
        </div>
        
    </div>
    <div class="license text-center">
        <img src="/assets/img/age-limit.png" alt="open only to persons over the age of 18 years"><br><br>
        This platform is open only to persons over the age of 18 years

Gambling may have adverse effects if not taken in moderation. Bet Responsibly
    </div>
    <div class="text-center">
        Contact Support
    </div>
    <div class="paybills-wrapper">
        <div class="paybill">
            Call: <br>
            <span style="color: rgba(255,255,255, .7); font-weight: 600; font-size:20px">#</span> 
        </div>
        <div class="paybill">
            SMS/WhatsApp: <br>
            <span style="color: rgba(255,255,255, .7); font-weight: 600; font-size:20px">#</span> 
        </div>
    </div>

    <div class="license text-center">
        UNDABET
    </div>
    
    <div class="quick-links">
        <div class="terms"  [routerLink]="['/how-to-play']">
            How To Play
        </div>
        <div class="terms"  [routerLink]="['/terms-and-conditions']">
            Terms & Privacy
        </div>
        <div class="privacy"  [routerLink]="['/responsible-gambling']">
            Responsible Gambling
        </div>
    </div>
</div>

</section>