<section class="container-fluid py-2">
  <div class="row-wrapper d-flex justify-content-around">
    <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
      <app-sidebar ></app-sidebar>
    </div>
    <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
      <!-- <app-carousel></app-carousel> -->
      <app-sportsnavbar></app-sportsnavbar>

      <app-shimmer [count]="10" [type]="'prematch'" *ngIf="(sportsService.gamesLoading | async)"></app-shimmer>

      <ng-container *ngIf="!(sportsService.gamesLoading | async)">
        <app-datewrapper *ngFor="let group of dateList" [matches]="group.value" [title]="getPrettyDate(group.value[0].start_time)"></app-datewrapper>
      </ng-container>
    </div>
    <div class="w-20 shadow-sm righ-side bg-light hide-mobile sticky">
      <app-betslip></app-betslip>
    </div>
  </div>

</section>
