export class JackpotBetslip{
    jp_match_id;
    parent_match_id;
    team1;
    team2;
    pick_key;
    position;
    special_bet_value;
    betType;
    odd_value;
    oddType;
    combi; // 1x2, Double chance, Total
    }
    