<ng-container *ngIf="type === 'bet'">
  <div class="trans-wrapper bets-wrapper">
    <div class="item-div d-flex justify-content-left" *ngFor="let b of [].constructor(count)">
      <div class="left">
      <span class="title">
        <ngx-shimmer-loading width="20px" height="28px" class="border-too"></ngx-shimmer-loading>
      </span> <br>
        <span class="date text-muted">
        <ngx-shimmer-loading width="30px" height="20px" class="border-too"></ngx-shimmer-loading>
      </span>
      </div>

      <div class="right text-success pt-1 w-100">
        <ngx-shimmer-loading class="d-flex" width="100%" height="24px" class="border-too"></ngx-shimmer-loading>

        <div class="stake-data d-flex justify-content-between">
          <div class="stake ">
            <div class="tit text-muted py-1">
            <span>
              <ngx-shimmer-loading width="64px" height="16px" class="border-too"></ngx-shimmer-loading>
            </span>
              <br>
              <span class="act-amount text-dark">
              <ngx-shimmer-loading width="40px" height="16px" class="border-too"></ngx-shimmer-loading>
            </span>
            </div>

          </div>

          <!-- income -->
          <div class="stake ">
            <div class="tit text-muted py-1 text-right">
            <span>
              <ngx-shimmer-loading width="84px" height="16px" class="border-too"></ngx-shimmer-loading>
            </span>
              <br>
              <span class="act-amount text-dark">
              <ngx-shimmer-loading width="44px" height="16px" class="border-too"></ngx-shimmer-loading>
            </span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'transaction'">
  <div class="trans-wrapper">
    <div class="item-div d-flex justify-content-between" *ngFor="let t of [].constructor(count)">
      <div class="left">
        <span class="title">
          <ngx-shimmer-loading width="200px" height="16px"></ngx-shimmer-loading>
        </span> <br>
        <span class="date text-muted">
          <ngx-shimmer-loading width="130px" height="14px"></ngx-shimmer-loading>
        </span>
      </div>

      <div class="right text-success pt-1">
        <ngx-shimmer-loading width="45px" height="40px"></ngx-shimmer-loading>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'prematch'">
  <div class="mega-card-wrapper mega-card-wrapper-gamer" *ngFor="let p of [].constructor(count)">
    <div class="card-wrapper">
      <div class="gamecard-header">
        <ngx-shimmer-loading width="230px" height="14px"></ngx-shimmer-loading>
      </div>
      <div class="gamecard-body">
        <div class="match-section">
          <div class="home-team">
            <ngx-shimmer-loading width="143px" height="16px"></ngx-shimmer-loading>
          </div>
          <div class="away-team">
            <ngx-shimmer-loading width="143px" height="16px"></ngx-shimmer-loading>
          </div>
        </div>
        <div class="odd-section odd-section-left" >
          <ngx-shimmer-loading class="odd-btn-shimmer ngx-shimmer" width="100%" height="32px"></ngx-shimmer-loading>
        </div>
        <div class="odd-section hide-mobile odd-section-left" >
          <ngx-shimmer-loading class="odd-btn-shimmer ngx-shimmer" width="100%" height="32px"></ngx-shimmer-loading>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'jackpot'">
  <div class="mega-card-wrapper px-0" *ngFor="let j of [].constructor(count)">
    <div class="card-wrapper">
      <div class="gamecard-header">
        <ngx-shimmer-loading width="230px" height="14px"></ngx-shimmer-loading>
      </div>
      <div class="gamecard-body">
        <div class="match-section">
          <div class="home-team">
            <ngx-shimmer-loading width="40px" height="16px"></ngx-shimmer-loading>
          </div>
          <div class="away-team">
            <ngx-shimmer-loading width="40px" height="16px"></ngx-shimmer-loading>
          </div>
        </div>
        <div class="odd-section ">
          <div class="btn-group" role="group" aria-label="Basic example">
            <ngx-shimmer-loading class="odd-btn-shimmer ngx-shimmer" width="100%" height="32px"></ngx-shimmer-loading>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>
