<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Log In</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <button #openForgotPassModal type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropForgotpass" hidden></button>
        <button #openRegisterModal type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropRegister" hidden></button>
        <button #openFinishRegistrationModal type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdropFinishRegistration" hidden></button>
      </div>
      <div class="modal-body">
          <form [formGroup]="loginForm">
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input formControlName="phone" type="text" [class.is-invalid]="phoneInValidVar" class="form-control" >
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input formControlName="password" type="password" [class.is-invalid]="passwordInValidVar" class="form-control">
              </div>
             <div class="forgot-pass py-1 mb-2 hover-pointer" (click)="forgotPass()">
                 Forgot your Password?
             </div>
              <button [disabled]="isLoading" (click)="onSubmit()" class="btn btn-success form-control">Log In</button>

              <div class="forgot-pass py-1 mb-2 mt-2 hover-pointer" (click)="register()">
                  Don't have an account? Register Here
              </div>
            </form>
      </div>
      
    </div>
  </div>