import {createReducer, on} from '@ngrx/store';

import {showBetslip, updatePage} from './main.actions';

export const initialState = {
    page: "home",
    showBetslip: false
}

const _mainReducer = createReducer(
    initialState,
    on(updatePage, (state, {page}) => ({
        ...state,
        page,
      })),
    on(showBetslip, (state, {showBetslip}) => ({
      ...state,
      showBetslip,
    })),
  );
  
  export function mainReducer(state, action) {
    return _mainReducer(state, action);
  }
  