import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SportsService } from 'backend';

@Component({
  selector: 'app-sportsnavbar',
  templateUrl: './sportsnavbar.component.html',
  styleUrls: ['./sportsnavbar.component.css']
})
export class SportsnavbarComponent implements OnInit {

  constructor(private sportsService: SportsService, private router: Router) { }
  sports: any[] = [];

  currentSport: any = {};
  currentLeague: any = null;
  currentTab: string = "Highlights";
  ngOnInit(): void {
    this.sportsService.activeSportStream.subscribe(payload => {
      this.sports = payload;
    });

    this.sportsService.currentSportStream.subscribe(currentSport => {
      this.currentSport = currentSport;
    });

    this.sportsService.currentLeagueStream.subscribe(currentLeague => {
      this.currentLeague = currentLeague;
    })

    this.sportsService.currentTabStream.subscribe(currenTab => {
      this.currentTab = currenTab;
      
    });

  }

  toggleTab(tab){
    if(tab == "Countries"){
      this.router.navigate(['/countries']);
    }else{
      this.currentLeague = null;
    }
    this.sportsService.currentTabStream.next(tab);
  }

  toggleSport(sport){
    this.sportsService.currentSportStream.next(sport);
  }

}
