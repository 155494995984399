<section class="mega-card-wrapper px-0">
    <div class="card-wrapper">
        <div class="gamecard-header" >
            <div class="time">
                {{ getPrettyDate(match.start_time) }} {{ getTime(match.start_time) }}
            </div>
            
            <div class="league">
                {{ match.category }}
            </div>
            <div class="jp-result" *ngIf="jackpotParams.active == 2" >
                {{ match.result }}
            </div>
        </div>
        <div class="gamecard-body">
            <div class="match-section">
                <div class="home-team">
                    {{ match.team1 }}
                </div>
                <div class="away-team">
                    {{ match.team2 }}
                </div>
            </div>
            <div class="odd-section " >
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button [class.disable-jackpot]="jackpotParams.active != 1" [disabled]="jackpotParams.active != 1"  type="button" class="btn btn-odds activ" [class.picked]="inSlip(match.jp_match_id, match.position, match.team1)" id="{{ match.jp_match_id + match.position + match.team1 }}" (click)="actOnButton(match, match.team1, match.position, '1x2', match.home_odd)">{{ match.home_odd }}</button>
                    <button [class.disable-jackpot]="jackpotParams.active != 1" [disabled]="jackpotParams.active != 1"  type="button" class="btn btn-odds" [class.picked]="inSlip(match.jp_match_id, match.position, 'draw')" id="{{ match.jp_match_id + match.position + 'draw' }}" (click)="actOnButton(match, 'draw', match.position, '1x2', match.neutral_odd)">{{ match.neutral_odd }}</button>
                    <button [class.disable-jackpot]="jackpotParams.active != 1" [disabled]="jackpotParams.active != 1"  type="button" class="btn btn-odds" [class.picked]="inSlip(match.jp_match_id, match.position, match.team2)" id="{{ match.jp_match_id + match.position + match.team2 }}" (click)="actOnButton(match, match.team2, match.position, '1x2', match.away_odd)">{{ match.away_odd }}</button>
                  </div>
            </div>
            
           
        </div>
        
    </div>
</section>
