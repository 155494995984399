import { Component, OnInit } from '@angular/core';
import { SportsService, BetslipService } from 'backend';

@Component({
  selector: 'app-jackpots',
  templateUrl: './jackpots.component.html',
  styleUrls: ['./jackpots.component.css']
})
export class JackpotsComponent implements OnInit {

  constructor(private sportService: SportsService, private betslipService: BetslipService) { }
  jackpots: any[] = [];
  expand = true;
  ngOnInit(): void {
    this.betslipService.activeBetslipStream.next('jackpot');
    this.sportService.fetchOfferedJackpot().subscribe(jackpots => {
      console.log(jackpots);
      this.jackpots = Object.entries(jackpots).map(([key, value]) => ({key, value}));
      console.log(this.jackpots);
    });

  }

}
