<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
      <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
        
      </div>
      <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
        <div class="topbar d-flex justify-content-left">
            
            <div class="text-light txt ">
               Responsible Gambling
            </div>

           
        </div>
        <div class="">
            <app-carousel></app-carousel>
            
        </div>
        <div class="txt-bonus px-2 py-2">
            <p>Betfiti is committed to Responsible Gaming. We are dedicated to make gaming as an enjoyable leisure activity and provide an enjoyable betting experience to all involved parties. The majority of players enjoy the entertainment and gaming services we provided but for some people, gaming may stop being a harmless leisure activity and become a problem. Betfiti has a series of measures for responsible betting which allow customers to limit their spending and it is a mature way of ensuring all involved parties stay in control.</p>
          <div class="title-b">
            Stay in control
          </div>
          <p>
            We believe that gaming should always be an enjoyable activity and our customers can have fun from betting. Please kindly remember the following tips, it may help you to keep in control and make sure gaming will not become a problem.
          </p>
          <ol>
            <li>Only place bets with amounts that you can afford to lose.</li>
            <li>Gambling should be entertaining and not be seen as a way of making money.</li>  
            <li>Bet sensibly and never chase your losses.</li>  
            <li>Keep track of the time and amount you spend.</li>  
            <li>Balance gambling with other activities. Think about if you are still having fun from betting if gaming is your only entertainment.</li>  
            <li>Take regular breaks from gambling.</li>  
            <li>Don’t gamble when under the abnormal conditions such as alcohol or when you’re upset.</li>  
            
          </ol>

          <div class="title-b">
            Getting Help 
          </div>
          <p>
            If you need to talk with somebody about betting problem, you can reach out to several self-help organizations that provide help and assistance to people who have a problem with betting.
          </p>
          <div class="title-b">
            Restrictions of Minors
          </div>
          <p>
            It is against the law for those under the age of 18 to gamble, and Betfiti takes its responsibility to prevent underage gambling very seriously.
          </p>
          <ol>
              <li>Players who are below the age of 18 are prohibited from gambling.</li>
              <li>We have measures to refuse underage betting.</li>
              <li>Each person should fill in detailed personal information to make sure that the person is over 18 years.</li>
              <li>The payment services of Betfiti are based on mobile money. Players have to be aged 18 or above to register for such services and they must have a valid national ID card.</li>
              <li>Other suggestions to avoid having minors from gambling at Betfiti:
                  <ul>
                      <li>Do not share details such as: username, password, among other sensitive information.</li>
                      <li>Lock your computer with a password if you are using a public or shared computer.</li>
                      <li>Enlighten your kids on any potential risks concerning gambling.</li>
                  </ul>
              </li>
              <li>Use Websites Filtering software. There are several third-party applications that you can use to monitor or restrict access to the internet:
                  <ul>
                      <li>
                        Net Nanny (www.netnanny.com): a general blocking software that protects children from accessing inappropriate web content. Also available for iOS and Android devices.
                      </li>
                      <li>
                        CYBERsitter (www.cybersitter.com): a filtering software that allows parents to choose which websites to block. 
                      </li>
                      <li>
                        GamBlock (www.gamblock.com): a specific blocking software for gambling websites. Also available for Android devices.
                      </li>
                  </ul>
              </li>

          </ol>

          <div class="title-b">
            Problem Recognition 
          </div>
          <p>
            Betfiti believes the majority of players enjoy the experience of betting responsibly however, for some reason, a small number of players might have a harmful impact from gambling. By answering such self-assessment questions below, it will help you consider if you have a problem:
          </p>
          <ol>
              <li>
                When you lose money, do you feel lost or depressed?
              </li>
              <li>
                When you lose money, do you want to gamble again to win money back ASAP?
              </li>
              <li>
                Do you run out of all money on betting in your account?
              </li>
              <li>
                Have you borrowed money from others and spend it on betting?
              </li>
              <li>
                Have you lost interest in your family, friends, or hobbies because of betting?
              </li>
              <li>
                Do you ever feel suicidal because of betting?
              </li>
          </ol>
          <div class="title-b">
            Family and Friends 
          </div>
          <p>
            Betfiti believes betting problems might harm the player’s friends and family. If you are concerned that one of your friends or family members has gambling issues, the following are some signs of such issues:
          </p>
          <ol>
              <li>
                Uncontrolled spending
              </li>
              <li>
                Lying about gaming behavior
              </li>
              <li>
                Constantly borrowing money or even stealing
              </li>
              <li>
                Lost interest in hobbies or has abnormal situations
              </li>
              <li>
                Neglects work or studies
              </li>
          </ol>
         <p>
            In cases such as above or other special cases, we highly recommend that you have the courage to seek help.


         </p>   

        </div>
        
        
      </div>
      <div class="w-20 shadow-sm righ-side bg-light hide-mobile">
       
      </div>
    </div>
  
  </section>
  