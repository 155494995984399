import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService, SportsService, BetslipService } from 'backend';
import { Betslip } from '../../models/Betslip';
import { Location } from '@angular/common';

interface MatchInterface {
  home_team: string;
  away_team: string;
  match_id: string;
  start_time: string;
  date: string;
  category: string;
  league: string;
}
@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.css']
})

export class MarketsComponent implements OnInit {

  constructor( private location: Location, private route: ActivatedRoute, private sportsService: SportsService, private backendService: BackendService, private betslipService: BetslipService) { }

  isFirst = true;
  betslip: Betslip[] = [];
  match_id:any;
  match: MatchInterface = {
    home_team: "",
    away_team: "",
    match_id: "",
    start_time: "",
    date: "",
    category: "",
    league: ""
  };
  markets: any[];
  
  ngOnInit(): void {
    this.betslipService.betslipStream.subscribe(betslip => {
      this.betslip = betslip;
    })
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.match_id = params.match;
        this.fetchMarkets();
      }
    );
  }

  goback()
  {
    this.location.back();
  }

  fetchMarkets()
  {
    this.sportsService.markets(this.match_id).subscribe(payload => {
      console.log(payload);
      var match = payload[0];
      this.setUpMatch(payload[0]);
      this.markets = this.groupBySubtype(payload);
      


    })
  }

  returnZero() {
    return 0;
    }

  isThreeWay(key){
    var onex2 = '1x2';
    if(key.includes(onex2) || key.toLowerCase() == "double chance"){
      return true;
    }else{
      return false;
    }
    
  }
  setUpMatch(match){
    this.match.home_team = match.home_team;
    this.match.away_team = match.away_team;
    this.match.match_id = match.match_id;
    this.match.date = this.backendService.getPrettyDate(match.start_time).toString();
    this.match.start_time = this.backendService.getTime(match.start_time).toString();
    this.match.category= match.category;
    this.match.league = match.competition_name;
  }

  groupBySubtype(array){
    return array.reduce((r, a) => {
          r[a.name] = r[a.name] || [];
          r[a.name].push(a);
          return r;
      }, Object.create(null));
  }

  actOnButton(odd, pick, sub_type_id, odd_type, odd_value, odd_id, parent_match_id)
  {
    // console.log(slip);
    var slip = new Betslip();
    slip.matchId = odd.match_id.toString();
    slip.homeTeam = odd.home_team;
    slip.awayTeam = odd.away_team;
    slip.betType = 'prematch';
    slip.oddType = odd_type;
    slip.odd_value = odd_value;
    slip.parent_match_id = odd.parent_match_id;
    slip.pick_key = pick;
    slip.sub_type_id = sub_type_id;
    slip.event_odd_id = odd_id;

    console.log(slip);

    var betslip = this.betslipService.addToBetslip(slip);
    this.betslipService.betslipStream.next(betslip);
    console.log(betslip);

  }

  toString(value){
    return value.toString();
  }
  inSlip(match_id, sub_type_id, pick_key){
    if(this.betslip != null && this.betslip != undefined){
      let check = this.betslip.find(i => i.matchId === match_id);
      if(check != undefined){
        if(check.sub_type_id == sub_type_id && check.pick_key == pick_key){
          return true;
        }
      }
    }
    
    return false;
  }

}
