<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
      <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
        <!-- <app-sidebar ></app-sidebar> -->
      </div>
      <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
        <app-carousel></app-carousel>

        <div class="category-wrapper d-flex justify-content-left">
            <div *ngFor="let category of categories" class="category-pill " [class.active]="activeCategory_.id == category.id" (click)="toggleCategory(category)">
                {{ category.title }}
            </div>
        </div>
       
        <div class="casino-header text-light">
            {{ activeCategory_.title }}
        </div>

        <div class="card-casino-wrapper">
            <div class="row">
                <div class="col-md-2 col-4 m-0 p-0" *ngFor="let game of items">
                    
                    <div class="casino-thumbnail" (click)="getLaunchUrl(game.launch_url)">
                        <img [src]="game.image" alt="" style="width: 100%; height: 140px; border-radius: 2px;">
                    </div>
                    
                </div>
                
            </div>
        </div>
        
      </div>
      <div class="w-20 shadow-sm righ-side bg-light hide-mobile sticky">
        
      </div>
    </div>
  
  </section>
  
