<header id="header">
    <div class="header-top shadow-sm container-fluid ">
        <div class="logo hover-pointer"  [routerLink]="['/']">
            <img src="/assets/logo.png" alt="undabet logo">
        </div>
        <div class="right-sec d-flex justify-content-right">
            <!-- <div class="search">
                <button class="btn " ><i class="bi bi-search text-light"></i></button>
            </div> -->
            <div class="account py-2" *ngIf="!(authService.authStream | async)">

                <button #openLoginModal class="btn btn-login px-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Log In</button>
                <button class="btn btn-register px-4" data-bs-toggle="modal" data-bs-target="#staticBackdropRegister">Register</button>
            </div>
            
            <div class="sec-logged-in" *ngIf="(authService.authStream | async)">
                <div class="mb-1">
                    <button [routerLink]="['/account']" class="btn form-control btn-account text-dark">
                        <i  class="bi bi-wallet"></i> <span class="px-2">My Account</span>
                    </button>
                </div>
                <div class="account is-logged-in" [routerLink]="['/account']" >
                    <div class="text text-right">
                        <span style="opacity: .8;">Freebet</span>  <br>
                        <span style="font-weight: 500;"> KES {{ (authService.authStream | async).free_bet }}</span>
                    </div>
                    <div class="text text-right">
                        <span style="opacity: .8;">Bonus</span>  <br>
                        <span style="font-weight: 500;"> KES {{ (authService.authStream | async).bonus }}</span>
                    </div>
                    <div class="text text-right">
                        <span style="opacity: .8;">Balance</span>  <br>
                        <span style="font-weight: 500;"> KES {{ (authService.authStream | async).balance }}</span>
                    </div>
                    <div class="text text-right">
                        <span style="opacity: .8;">User</span>  <br>
                        <span style="font-weight: 500;"> {{ (authService.authStream | async).username }}</span>
                    </div>
                    <div>
                        <img src="/assets/img/account.png" alt="">
                    </div>


                </div>

            </div>

        </div>

    </div>
    <div class="header-row-2 shadow-sm">
        <!-- Start Icon -->
        <div class="icons-wrapper" [class.active]="(activePage | async) === '/'" [routerLink]="['/']">
            <div class="icon">
                <i  class="bi bi-house-door"></i>
            </div>
            <div class="text">
                Home
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper" [class.active]="(activePage | async) === '/virtuals'" [routerLink]="['/virtuals']">
            <div class="icon">
                <i  class="bi bi-joystick"></i>
            </div>
            <div class="text">
                Virtuals
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <!-- <a href="/casino" style="color: unset; text-decoration: none;"> -->
        <div class="icons-wrapper" [class.active]="(activePage | async) === '/casino'" [routerLink]="['/casino']">
           
            <div class="icon">
                <i  class="bi bi-controller"></i>
            </div>
            <div class="text">
                Casino
            </div>
        </div>

    <!-- </a> -->
        <!-- end icon -->
          <!-- Start Icon -->
          <!-- <a href="https://kapamia.undabet.co.ke" target="_blank" style="color: unset; text-decoration: none;">
          <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-wallet2"></i>
            </div>
            <div class="text">
                Kapamia
            </div>
        </div>
        </a> -->
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper" [class.active]="(activePage | async) === '/live-casino'" [routerLink]="['/live-casino']">
            <div class="icon">
                <i  class="bi bi-cash-coin"></i>
            </div>

            <div class="text">
                Live Casino
            </div>
        </div>
        <!-- end icon -->

        <!-- Start Icon -->
        <!-- <div class="icons-wrapper" [class.active]="(activePage | async) === '/bonus'" [routerLink]="['/bonus']">
            <div class="icon">
                <i  class="bi bi-gift"></i>
            </div>
            <div class="text">
                Bonus
            </div>
        </div> -->
        <!-- end icon -->
        <!-- Start Icon -->
        <!-- <div class="icons-wrapper" [class.active]="(activePage | async) === '/app'" [routerLink]="['/app']">
            <div class="icon">
                <i  class="bi bi-phone"></i>
            </div>
            <div class="text">
                App
            </div>
        </div> -->
        <!-- end icon -->
        <!-- Start Icon -->
        <!-- <div class="icons-wrapper" [class.active]="(activePage | async) === '/how-to-play'" [routerLink]="['/how-to-play']">
            <div class="icon">
                <i  class="bi bi-info-circle"></i>
            </div>
            <div class="text">
                Help
            </div>
        </div> -->
        <!-- end icon -->

    </div>
</header>
