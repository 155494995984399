<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Recover Password?</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <button #openLoginModal type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" hidden></button>
        <button #openFinishRegistrationModal type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdropFinishRegistration" hidden></button>
      </div>
      <div class="modal-body">
          <form [formGroup]="forgotPasswordForm">
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input formControlName="phone" type="text" [class.is-invalid]="phoneInValidVar" class="form-control" >
              </div>
              <div class="mb-3" [class.d-none]="(currentStep | async) == 'forgot'">
                <label for="password" class="form-label">Reset Code</label>
                <input formControlName="reset_code" type="number" [class.is-invalid]="resetCodeInvalidVar"  class="form-control">
              </div>
              <div class="mb-3" [class.d-none]="(currentStep | async) == 'forgot'">
                <label for="password" class="form-label">Password</label>
                <input formControlName="password" type="password" [class.is-invalid]="passwordInValidVar" class="form-control">
              </div>
             
              <button (click)="onSubmit()" class="btn btn-success form-control">Proceed</button>

              <div class="forgot-pass py-1 mb-2 mt-2" (click)="register()">
                  Don't have an account? Register Here
              </div>
            </form>
      </div>
      
    </div>
  </div>