import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';

import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { Store } from '@ngrx/store';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { BackendService, SportsService, LocalStorageServiceService, BetslipService, Betamount, AuthService } from 'backend';
import { Betslip } from './models/Betslip';
import { JackpotBetslip } from './models/JackpotBetslip';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {interval, Subscription} from 'rxjs';
import { Auth } from './models/Auth';
const source = interval(15000);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private _bottomSheet: MatBottomSheet,
              private store: Store<{ mainState: any }>,
              private authService: AuthService,
              private sportService: SportsService,
              private backendService: BackendService,
              private localStorage: LocalStorageServiceService,
              private betslipService: BetslipService,
              private router: Router, private activatedRoute: ActivatedRoute
  ) {
    this.getDevice();
  }

  currentSport: any;
  currentLeague: any = null;
  activeSports: any[] = [];
  currentTab: any;
  navData: any[] = [];
  fetchSportMatches = false;
  betslip: Betslip[] = [];
  jackpotBetslip: JackpotBetslip[] = [];
  betamount: Betamount = {
    stake : 49,
    stake_after_tax : 0,
    total_odds : 1,
    raw_possible_win : 0,
    withholding_tax : 0,
    net_possible_win : 0,
    bonus: 0,
  };
  activeBetslip = 'prematch';
  esport: number = 0;
  
  @ViewChild('openLoginModal') openLoginModal: ElementRef
  ngOnInit(): void {
    // source.subscribe(val => this.authService.getProfile()?.subscribe(response => {
    //   //  console.log(response);
    //   if (response.res == 0 ) {
    //     this.generateAuthWithBalance(this.authService.authStream.value, response.balance, response.bonus, response.free_bet);
    //   } else {
    //     // Display errors
    //   }
    // },
    // error => {
      
    //   console.log(error);
    // }));
    // throw new Error('Method not implemented.');
    this.authService.loginPrompt.subscribe((msisdn: string) => {
      if (msisdn) {
        /* todo :: send login prompt */
        this.openLoginModal.nativeElement.click();
        
      }
    });

    this.router.events
      .pipe(
          filter((event: any) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route: ActivatedRoute) => {
              while (route.firstChild) {
                  route = route.firstChild;
              }
              return route;
          }),
          switchMap((router: ActivatedRoute) => router.data)
      )
      .subscribe((data: Data) => {
          // console.log(data);
          if(data?.esport !== undefined){
            if(data.esport != this.esport){
              this.esport = data.esport;
              if(this.esport == 0){
                // console.log("am fetching sport data");
                this.fetchSportData();
              }
              if(this.esport == 1){
                this.fetchSportData();
                
              }
            }
            
          }
          console.log(this.esport);
      });

      
    if (!this.authService.checkAuth()) {
      this.localStorage.clearAuthFromStorage();
    }else{
      this.authService.getProfile()?.subscribe(response => {
        //  console.log(response);
        if (response.res == 0 ) {
          this.generateAuthWithBalance(this.authService.authStream.value, response.balance, response.bonus, response.free_bet);
        } else {
          // Display errors
        }
      },
      error => {
        
        console.log(error);
      });
    }

    this.sportService.currentSportStream.subscribe(currentSport => {
      this.currentSport = currentSport;
    });
    this.sportService.currentLeagueStream.subscribe(currentLeague => {
      this.currentLeague = currentLeague;
      console.log(currentLeague)
        if(currentLeague?.id !== undefined && currentLeague?.id !== null){
          console.log(this.currentLeague.id);
          const sport = this.getSPortById(this.currentLeague.sport_id);
          this.sportService.currentSportStream.next(sport);
          this.fetchMatchesByCompetition(sport.type, this.currentLeague.id, 0, 150);
          this.fetchSportMatches = true;
        }else if(currentLeague == null){
          this.fetchMatchesBySport(this.currentSport.type, this.currentSport.sport_id, 0, 150);
        }
       
    
      
    });
    this.sportService.currentTabStream.subscribe(currentTab => {
      this.currentTab = currentTab;
      if (this.currentTab === 'Highlights') {
        if (this.currentSport.sport_name === 'Soccer' && this.esport == 0) {
          this.fetchTopSoccer();
        } else {
          if (this.currentSport.type != null) {
            this.fetchMatchesBySport(this.currentSport.type, this.currentSport.sport_id, 0, 150);
           
          }
        }
      }else if (this.currentTab === 'Countries'){
        // Do nothing
      }else{
        this.fetchMatchesByDate(currentTab, this.currentSport.type, this.currentSport.sport_id);
        // this.sportService.currentLeagueStream.next(nul);
      }

    });
    this.betslipService.betslipStream.subscribe(betslip => {
      if (Array.isArray(betslip)){
        this.betslip = betslip;
        this.betamount = this.betslipService.getBetAmount(49);
      }

    });
    this.betslipService.jackpotBetslipStream.subscribe(jackpotBetslip => {
      if (Array.isArray(jackpotBetslip)){
        this.jackpotBetslip = jackpotBetslip;
      }
    });
    this.betslipService.activeBetslipStream.subscribe(activeBetslip => {
      this.activeBetslip = activeBetslip;
    });
    if(this.esport == 0){
      this.fetchSportData();
      this.fetchTopSoccer();
    }
    this.fetchActiveSportData();
    this.fetchBetslip();
    this.fetchJackpotBetslip();
    this.fetchAppLimits();
  }

  generateAuthWithBalance(user,balance, bonus, free_bet){
    console.log(balance);
    var auth = new Auth();
    auth.username = user.username;
    auth.profile_id = user.profile_id;
    auth.balance = balance;
    auth.token = user.token;
    auth.bonus = bonus;
    auth.free_bet = free_bet;
    auth.virtual_code = user.virtual_code;
    auth.virtual_token = user.virtual_token;

    this.authService.authStream.next(auth);
    this.localStorage.setAuthToStorage(auth);
    return auth;
  }

  fetchAppLimits(): void{
    this.betslipService.fetchLimits().subscribe(limits_ => {
      let limits = JSON.parse(limits_);
      this.betslipService.appLimits.next(limits);

        let is_boosted = limits.find(i => i.name === "isBoosted" );

        let boost_rate = limits.find(i => i.name === "boost_rate" );
        
        let boost_min_count = limits.find(i => i.name === "boost_min_count" );
        
        let boost_max = limits.find(i => i.name === "boost_max" );
        
        let boost_min_odds = limits.find(i => i.name === "boost_min_odds" );
        
        let boostPayload = {
          isBoosted: is_boosted.limit,
          boost_rate: boost_rate.limit,
          boost_min_count: boost_min_count.limit,
          boost_max: boost_max.limit,
          boost_min_odds: boost_min_odds.limit
        }
        this.betslipService.betBoosterStream.next(boostPayload);
      console.log(boostPayload);
    })
  }
  fetchBetslip(): void {
    const betslip = this.localStorage.getBetslipFromStorage();
    if (betslip !== null && betslip !== undefined && betslip.length > 0) {
      this.betslipService.betslipStream.next(betslip);
    }

  }

  fetchJackpotBetslip(): void {
    const betslip = this.localStorage.getJackpotBetslipFromStorage();
    if (betslip !== null && betslip !== undefined && betslip.length > 0) {
      this.betslipService.jackpotBetslipStream.next(betslip);
    }

  }

  toTwoDp(value): any {
    return this.betslipService.toTwoDp(value);
  }

  openBottomSheet(): void {
    this._bottomSheet.open(BottomSheetComponent);
  }

  fetchSportData(): void {
    this.sportService.fetchNavData(this.esport).subscribe(data => {
      this.sportService.navDataStream.next(data);  
      let sport = data[0].sport;
      this.sportService.currentSportStream.next(data[0].sport);  
      this.navData = data;  
      
      if(this.esport == 1){
        this.sportService.currentLeagueStream.next(null);
      }
      if(this.esport == 0){
        this.fetchTopSoccer();
      }
    });
  }

  fetchAllMatches(): void {

  }

  fetchActiveSportData(): void {
    this.sportService.fetchActiveSports().subscribe(data => {
      this.activeSports = data;
      // console.log(data);
    });
  }

  fetchTopSoccer(): void {
    this.sportService.gamesLoading.next(true);
    this.sportService.matchesStream.next([]);
    this.sportService.fetchTopSoccer(0, 100).subscribe(data => {
      this.sportService.matchesStream.next(data.result);
      this.fetchSportMatches = true;
      // console.log(data);
      this.sportService.gamesLoading.next(false);
    }, error => {
      this.sportService.gamesLoading.next(false);
    });
  }

  fetchMatchesBySport(type, sport, skip, limit): void {
    this.sportService.gamesLoading.next(true);
    this.sportService.matchesStream.next([]);
    this.sportService.fetchMatchesBySport(type, sport, skip, limit, this.esport).subscribe(payload => {
      this.sportService.matchesStream.next(payload.result);
      this.sportService.gamesLoading.next(false);
    }, error => {
      this.sportService.gamesLoading.next(false);
    });
  }

  fetchMatchesByCompetition(type, league, skip, limit): void {
    this.sportService.gamesLoading.next(true);
    this.sportService.matchesStream.next([]);
    this.sportService.fetchMatchesByCompetition(type, league, skip, limit, this.esport).subscribe(payload => {
      this.sportService.matchesStream.next(payload.result);
      this.sportService.gamesLoading.next(false);
    }, error => {
      this.sportService.gamesLoading.next(false);
    });
  }

  fetchMatchesByDate(tab, type, sport_id): void {
    this.sportService.gamesLoading.next(true);
    this.sportService.matchesStream.next([]);
    if (tab === 'Today') {
      this.sportService.date(type, sport_id, this.backendService.getTodayDate(), this.esport).subscribe(payload => {
        this.sportService.matchesStream.next(payload.result);
        this.sportService.gamesLoading.next(false);
      }, error => {
        this.sportService.gamesLoading.next(false);
      });
      // console.log(this.backendService.getTodayDate());
    } else {
      this.sportService.date(type, sport_id, this.backendService.getTomorrowDate(), this.esport).subscribe(payload => {
        this.sportService.matchesStream.next(payload.result);
        this.sportService.gamesLoading.next(false);
      }, error => {
        this.sportService.gamesLoading.next(false);
      });
      // this.sportService.date(type, sport_id, this.backendService.getTomorrowDate());
    }
  }

  getSPortById(sport_id): any {
    console.log(sport_id);
    console.log(this.activeSports);
    const sport = this.activeSports.find(x => x.sport_id === sport_id);
    console.log(sport);
    this.fetchSportMatches = false;
    this.sportService.currentSportStream.next(sport);

    return sport;
  }

  @HostListener('window:resize', ['$event'])
  getDevice(): void {
    const size = window.innerWidth;
    // if (window.screen.orientation.type !== 'portrait-primary' && window.screen.orientation.type !== 'portrait-secondary') {
    //   size = window.innerHeight;
    // }
    if (size <= 768) {
      this.backendService.deviceStream.next('mobile');
    } else {
      this.backendService.deviceStream.next('desktop');
    }
  }
}
