<app-header class="hide-mobile"></app-header>
<app-headermobile class="hide-desktop"></app-headermobile>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div class="slip-wrapper hide-desktop" *ngIf="betslip.length > 0 || jackpotBetslip.length > 0">
  <div *ngIf="activeBetslip == 'jackpot'" class="slip shadow d-flex justify-content-left" (click)="openBottomSheet()">
    <div class="matches " style="padding-right: 0px!important;">
      Slip <span class="popi">{{ jackpotBetslip.length }} </span> 
    </div>
    
  </div>
  <div *ngIf="activeBetslip == 'prematch'" class="slip shadow d-flex justify-content-left" (click)="openBottomSheet()">
    <div class="matches ">
      Slip <span class="popi">{{ betslip.length }} </span> 
    </div>
    <div class="totalodd">
      Odds <span class="popi">{{ toTwoDp(betamount.total_odds) }}</span> 
    </div>
  </div>
</div>
<div class="modal " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <app-login></app-login>
  </div>

  <div class="modal " id="staticBackdropRegister" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <app-register></app-register>
  </div>

  <div class="modal " id="staticBackdropForgotpass" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <app-forgotpassword></app-forgotpassword>
  </div>
  <div class="modal " id="staticBackdropFinishRegistration" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <app-registerfinish></app-registerfinish>
  </div>

  <div class="modal " id="staticBackdropShareBet" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <app-sharebetslip></app-sharebetslip>
  </div>

  <button #openLoginModal type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdrop" hidden></button>

 