import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild,  } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BetslipService, Betamount, AuthService } from 'backend';

// import { BetslipService } from '../../../../../backend/src/lib/betslip.service';
import { DepositAndPlaceBetComponent } from '../../dialogs/deposit-and-place-bet/deposit-and-place-bet.component';
import { Betslip } from '../../models/Betslip';
import { Bet } from '../../models/Bet';
import { Sms } from '../../models/Sms';
import { DialogComponent } from '../dialog/dialog.component';
import { HeaderComponent } from '../header/header.component';
import { DepositPlaceBet } from '../../models/DepositPlaceBet';

@Component({
  selector: 'app-betslip',
  templateUrl: './betslip.component.html',
  styleUrls: ['./betslip.component.css']
})
export class BetslipComponent implements OnInit {
  @Output() hideBetslipEvent = new EventEmitter<any>();
  @ViewChild('openLoginModal') openLoginModal: ElementRef
  @ViewChild('openShareBetslipModal') openShareBetslipModal: ElementRef
  constructor(private betslipService: BetslipService, public authService: AuthService, public dialog: MatDialog, public depositPlaceBetDialog: MatDialog,  ) { }
  betslip: Betslip[] = [];
  betamount: Betamount;
  betslipForm = new FormGroup({
    stake: new FormControl('')
  });
  stake:number = 49;
  auth: any;
  isLoading:boolean = false;
  free_bet: number = 0;
  matches_to_add: number = 0;
  ngOnInit(): void {

    this.betslipService.betslipStream.subscribe(betslip => {
      this.betslip = betslip;
      this.checkForBonusApproach(betslip);
      this.betamount = this.betslipService.getBetAmount(this.stake);
      // console.log(this.betamount);
    });
    this.betslipService.betBoosterStream.subscribe(stream => {
      this.betamount = this.betslipService.getBetAmount(this.stake);
    })
    this.betslipForm.setValue({
      stake: 49
    });
    this.authService.authStream.subscribe(auth => {
      this.auth = auth;
    })
  }

  openDialog(title, message, status){
    this.dialog.open(DialogComponent, {data: { message: message , title: title, status: status}})

  }

  checkForBonusApproach(betslip){
    let appLimits = this.betslipService.appLimits.value;
    
    if(appLimits != null && appLimits != undefined && appLimits.length > 0){
      
      let boost_min_count = appLimits.find(i => i.name === "boost_min_count" ).limit;
      // console.log(boost_min_count)
      if(boost_min_count != undefined){
        if((boost_min_count - betslip.length) < 3 ){
          this.matches_to_add = (boost_min_count - betslip.length)+1;
        }else{
          this.matches_to_add = 0;
        }
      }else{
        this.matches_to_add = 0;
      }
    }else{
      this.matches_to_add = 0;
    }
    
  }


  onKey(event: any) {
    this.somethingChanged();
  }
  hideBetslip() {
    this.hideBetslipEvent.emit();
  }

  somethingChanged()
  {
    this.stake = this.betslipForm.value.stake;
    this.betamount = this.betslipService.getBetAmount(this.stake);
  }
  removeMatch(slip){
    var betslip = this.betslipService.removeMatch(slip);
    this.betslipService.betslipStream.next(betslip);
  }

  toTwoDp(value){
    return this.betslipService.toTwoDp(value);
  }

  clearSlip()
    {
      
      this.betslipService.clearSlip();
      this.betslipService.betslipStream.next([]);
    }

  
    placeBet(){

      this.isLoading = true;
      var user  = this.auth;
      console.log(user);
      if(user == null || user == undefined){
        this.isLoading = false;
        return this.openLoginModal.nativeElement.click();
      }
      // generate betstring
      let _betString = "";
      for( var i = 0; i < this.betslip.length; i++){ 
        this.betslip[i]
        if(_betString == ""){
          _betString = _betString + this.betslip[i].parent_match_id + "_" + this.betslip[i].event_odd_id;
        }else{
          _betString = _betString + "#" +  this.betslip[i].parent_match_id + "_" + this.betslip[i].event_odd_id;
        }
        
    
      }
      var slips = this.betslip;
      var betObject = new Bet();
      betObject.app_name = 'web_ui';
      betObject.bet_string = _betString;
      betObject.bet_total_odds = this.betamount.total_odds.toString();
      betObject.possible_win = this.betamount.raw_possible_win.toString();
      betObject.profile_id = user.profile_id;
      betObject.stake_amount = this.betamount.stake;
      betObject.stake_after_tax = this.betamount.stake - (this.betamount.stake - (this.betamount.stake/1.075));
      betObject.slip = this.betslip;
      betObject.type = "PRE_MATCH";
      betObject.redeem_free_bet = this.free_bet;

      // console.log(betObject);

      var response = this.betslipService.placeBet(betObject, user);

      response.subscribe((response: {res: any, description: any}) => {
        console.log(response);
        

        if(response.res == 0){
          this.openDialog("Bet placed Successfully", response.description, "success");
          // this.errorMessage = "";
          this.clearSlip();
          this.isLoading = false;
          var sms = new Sms();
          sms.recipients = user.username;
          sms.message = response.description;
          sms.insertDb = true;
          this.betslipService.betslipStream.next([]);
          this.betslipForm.setValue({
            stake: 49
          });
          this.stake = 49;
          this.authService.sendSMS(sms).subscribe(res => {
            // console.log(response);
          },
          err => console.log('HTTP Error', err),
          );
        }else if(response.res == 110){
          this.isLoading = false;
          this.openDepositPlaceBetDialog(betObject ,response.description);
        }else{
          this.isLoading = false;
          this.openDialog("Oops!", response.description, "error");
        }
        
      },
      err => {
        // this.errorMessage = err?.error;
        // Log user out
        this.isLoading = false;
        console.log(err);
      } )

    }

    shareBet()
    {
      let _betString = "";
      for( var i = 0; i < this.betslip.length; i++){ 
        this.betslip[i]
        if(_betString == ""){
          _betString = _betString + this.betslip[i].parent_match_id + "_" + this.betslip[i].event_odd_id;
        }else{
          _betString = _betString + "#" +  this.betslip[i].parent_match_id + "_" + this.betslip[i].event_odd_id;
        }
      }
      this.betslipService.bookingCode(_betString, "prematch").subscribe(response => {
        let res = response.toString();
        // console.log(JSON.parse(response));
        this.betslipService.bookingCodeStream.next(res)
        this.openShareBetslipModal.nativeElement.click();
      })
       
    }

  openDepositPlaceBetDialog(payload, message){
    if(message == "" || message == null || message == undefined || payload == null || payload == undefined){
      message = "Oops! Looks like something went wrong. Please try again.";
      this.dialog.open(DialogComponent, {data: { message: message }});
    }else{
      const dialogRef = this.depositPlaceBetDialog.open(DepositAndPlaceBetComponent, {data: { message: message, payload: payload }} );
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if(result){
          this.depositPlaceBet(payload);
        }
      });
    }
    
    // this.dialog.open(DialogComponent, {data: { message: message, payload: payload }})

  }
  activateFreebet(amount){
    this.free_bet = 1;
    this.stake = amount;
    this.betslipForm.patchValue({stake: amount});
    this.betamount = this.betslipService.getBetAmount(amount);
  }
  depositPlaceBet(betObject){
    var user  = this.auth;
    if(user != null && user != undefined){
      var _newBetObject = new DepositPlaceBet();
      _newBetObject.bet_data = betObject;
      _newBetObject.phone = user.username;
    }else{
      return this.openDialog("Oops!", " Looks like something went wrong. Please try again.", "error");
    }
    

    var response = this.betslipService.depositPlaceBet(_newBetObject, user);
    response.subscribe((response: {statusCode: any, message: any}) => {
      console.log(response);
      

      if(response.statusCode == 200){
        this.openDialog( "Deposit and Place bet", "A deposit prompt has been sent to your phone. Enter you password to deposit and place bet.", 'success' );
        this.clearSlip();
        this.isLoading = false;
        
        
      }else{
        this.isLoading = false;
        this.openDialog("Deposit and Place bet", response.message, "info");
      }
      
    },
    err => {
      // this.errorMessage = err?.error;
      this.openDialog("Oops", "Looks like something went wrong, Please try again later.", "error");
      
      this.isLoading = false
    } )

  }


}
