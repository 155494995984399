import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MyBet} from 'backend';
import {AuthService} from 'backend';
import {BackendService} from 'backend';

@Component({
  selector: 'app-bets',
  templateUrl: './bets.component.html',
  styleUrls: ['./bets.component.css']
})
export class BetsComponent implements OnInit, OnDestroy {

  private readonly _onDestroy: Subject<void> = new Subject<void>();

  myBets: BehaviorSubject<MyBet[]> = new BehaviorSubject<MyBet[]>([]);
  currentTab: BehaviorSubject<'today' | 'week' | 'month' | 'all'> = new BehaviorSubject<'today' | 'week' | 'month' | 'all'>('today');
  shimmerLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private authService: AuthService, private backendService: BackendService) { }

  ngOnInit(): void {
    this.getBets(0);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  getBets(days?: number): void {
    this.shimmerLoading.next(true);
    this.myBets.next([]);
    this.authService.getMyBets(this.authService.authStream.value, days)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(response => {
        console.log(`My bets ${JSON.stringify(response)}`);
        this.myBets.next(response);
        this.shimmerLoading.next(false);
      }, error => {
        console.warn(`Get my bets error [ ${error?.message} ]`, error);
        this.shimmerLoading.next(false);
      });
  }

  getDate(date: string, isMonth: boolean): string | number {
    if (date !== null && date !== undefined) {
      const _date = this.backendService.getDateWell(date.replace('T', ' '));
      if (_date !== null && _date !== undefined) {
        if (isMonth) {
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          return months[_date.getMonth()];
        } else {
          return _date.getDate();
        }
      }
    }
    return '';
  }
}
