<section>
    <div class="header-row-2">
        <!-- Start Icon -->
        <div class="icons-wrapper active" [routerLink]="['/']">
            <div class="icon">
                <i  class="bi bi-house-door"></i>
            </div>
            <div class="text">
                Home
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-search"></i>
            </div>
            <div class="text">
                Search
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-controller"></i>
            </div>
            <div class="text">
                League
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-cash-coin"></i>
            </div>
            
            <div class="text">
                Jackpots
            </div>
        </div>
        <!-- end icon -->
        
        <!-- Start Icon -->
        <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-gift"></i>
            </div>
            <div class="text">
                Bonus
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-phone"></i>
            </div>
            <div class="text">
                App
            </div>
        </div>
        <!-- end icon -->
        <!-- Start Icon -->
        <div class="icons-wrapper">
            <div class="icon">
                <i  class="bi bi-info-circle"></i>
            </div>
            <div class="text">
                Help
            </div>
        </div>
        <!-- end icon -->
        
    </div>
</section>