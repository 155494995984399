<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">

      <div class="carousel-item active ">
        <img src="/assets/banners/casino.webp" class="d-block w-100" alt="welcome to undabet">
      </div>
     
      
     
    </div>
  </div>