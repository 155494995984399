import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BetslipService } from 'backend';

@Component({
  selector: 'app-sharebetslip',
  templateUrl: './sharebetslip.component.html',
  styleUrls: ['./sharebetslip.component.css']
})
export class SharebetslipComponent implements OnInit {

  constructor(private router: Router, private betslipService: BetslipService) { }
  code: string = "";
  url: string = "";
  whatsappUrl: string = "";
  facebookUrl: string = "";
  twitterUrl: string = "";
  telegramUrl: string = "";
  successMessage: string = "";

  @ViewChild('copyText') copyText: ElementRef
  ngOnInit(): void {
    
    this.betslipService.bookingCodeStream.subscribe(code => {
      if( code != null &&  code != undefined && code != ""  ){
        this.successMessage = "";
        this.url = window.location.href;
        this.url = this.url + "?bookingCode=" + code;
        this.code = code;
        let encodedUrl = encodeURI(this.url);
        this.whatsappUrl = "https://wa.me/?text=" + encodedUrl;
        this.facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodedUrl;
        this.twitterUrl = "http://twitter.com/share?url=" + encodedUrl;
        this.telegramUrl = "https://telegram.me/share/url?url=" + encodedUrl;


      }
      
    })
  }

  copyCode(inputEl)
  {
    inputEl.select();
    document.execCommand('copy');
    this.successMessage = "Copied!";
  }

}
