import {Component, Input, OnInit} from '@angular/core';

import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { AuthService, SportsService } from 'backend';
import { Router } from '@angular/router';

interface SportInterface {
  name: string;
  children?: SportInterface[];
  count?: number;
  id?: number;
  sport_id?: number;
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {
  treeControl = new NestedTreeControl<SportInterface>(node => node.children);
  dataSource = new MatTreeNestedDataSource<SportInterface>();

  @Input() sport:any;
  constructor(private sportsService: SportsService, private router: Router, private authService: AuthService) { 
  }
  activePage='/';
  hasChild = (_: number, node: SportInterface) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.authService.activePage.subscribe(page => {
      this.activePage = page;
    })
    const arr: SportInterface[] = [];
    const arrCountries: SportInterface[] = [];
    const a = {
      name: 'Top Leagues',
      children: this.sport.top_leagues
    };
    const countries = this.groupByType(this.sport.other_leagues);
    for (const key in countries) {
      if (key !== null && key !== undefined) {
        const value = countries[key];
        const b = {
          name: key,
          children: value
        };
        arrCountries.push(b);
      }
    }
    const d = {
      name: 'All Matches',
      id: 0,
      sport_id: this.sport.sport.sport_id
    };
    const c = {
      name: 'Countries',
      children: arrCountries
    };


    // console.log(this.groupByType(this.sport.other_leagues));
    arr.push(d);
    arr.push(a);
    arr.push(c);

    this.dataSource.data = arr;
  }

  expand(node: any): string {
    if (node.name === 'Top Leagues') {
      this.treeControl.expand(node);
    }
    return this.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right';
  }

  groupByType(array): [] {
    return array.reduce((r, a) => {
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));
  }

  toggleCompetition(competition_id, sport_id){
    if(competition_id == 0){
      console.log(sport_id);

      this.sportsService.currentSportStream.next(this.sport.sport);
      this.sportsService.currentLeagueStream.next(null);
    }else{
      var league = this.sport.other_leagues.find(x => x.id === competition_id);
      console.log(this.router.url);
      if(this.router.url != '/' && this.router.url != '/e-sports'){
        this.router.navigate(['/']);
      }
      
      this.sportsService.currentLeagueStream.next(league);
    }
    
  }
}
