import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {AuthService, BetDetail, MyBet} from 'backend';
import {takeUntil} from 'rxjs/operators';
import {BackendService} from 'backend';

@Component({
  selector: 'app-betdetail',
  templateUrl: './betdetail.component.html',
  styleUrls: ['./betdetail.component.css']
})
export class BetdetailComponent implements OnInit, OnDestroy {

  private readonly _onDestroy: Subject<void> = new Subject<void>();

  bet: BehaviorSubject<MyBet> = new BehaviorSubject<MyBet>(null);
  betDetails: BehaviorSubject<BetDetail[]> = new BehaviorSubject<BetDetail[]>([]);

  constructor(private route: Router, private authService: AuthService, private backendService: BackendService) {
    const _bet: MyBet = route.getCurrentNavigation()?.extras?.state?.bet;
    if (_bet !== null && _bet !== undefined) {
      this.bet.next(_bet);
      this.authService.getBetDetails(this.authService.authStream.value, _bet.bet_id).pipe(takeUntil(this._onDestroy))
        .subscribe(details => {
          this.betDetails.next(details);
          console.log("detas: " + JSON.stringify(details));
        });
    } else {
      this.bet.next(null);
    }
  }

  ngOnInit(): void {
    this.bet.pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        if (data === null || data === undefined) {
          console.log(data);
          this.route.navigate(['/bets']).finally();
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  getDate(_date?: string): string {
    let date = _date + ":00";
    if (date !== null && date !== undefined) {
      const _s = date.replace('T', ' ');
      const _date = this.backendService.getDateWell(_s);
      if (_date !== null && _date !== undefined) {
        return _date.getDate() + '/' + (_date.getMonth() + 1) + ' ' + this.backendService.getTime(_s);
      }
    }
    return '';
  }
}
