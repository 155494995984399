import { Component, OnInit } from '@angular/core';
import { AuthService, SportsService } from 'backend';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.css']
})
export class CasinoComponent implements OnInit {

  constructor(private sportsService: SportsService, private authService: AuthService) { }
  auth: any = null;
  items: any[] = [];
  categories: any[] = [
    {
    "id" : 9,
    "title" : "Popular"
    },
    {
      "id" : 4,
      "title" : "Slots"
    },
    {
      "id" : 16,
      "title" : "New"
    },
    {
      "id" : 12,
      "title" : "Drops & Wins"
    },
    {
      "id" : 17,
      "title" : "Table Games"
    },
    {
      "id" : 18,
      "title" : "Burst"
    },
    {
      "id" : 0,
      "title" : "All"
    },
  ];
  activeCategory: BehaviorSubject<any> = new BehaviorSubject<any>({
    "id" : 9,
    "title" : "Popular"
    });
    activeCategory_:any = {
      "id" : 9,
      "title" : "Popular"
      };
    
  ngOnInit(): void {

    this.sportsService.fetchCasino(9).subscribe(result => {
      console.log(result);
      this.items = result.message[0].games;
    },err => {
      console.log(err)
    });

    this.authService.authStream.subscribe(auth => {
      this.auth = auth;
    })

    this.activeCategory.subscribe(category => {

      this.activeCategory = category;
      this.sportsService.fetchCasino(category.id).subscribe(result => {
        console.log(result);
        this.items = result.message[0].games;
      },err => {
        console.log(err)
      });
    })
  }

  toggleCategory(category){
    console.log(category)
    this.activeCategory_ = category
    this.sportsService.fetchCasino(category.id).subscribe(result => {
      console.log(result);
      this.items = result.message[0].games;
    },err => {
      console.log(err)
    });
  }

  getLaunchUrl(launch_url){
    if(this.auth == null){
      return launch_url;
    }
    var profile = this.auth;
    var tokn =profile.virtual_token;
    tokn = tokn.replaceAll("Bearer ", "");

    console.log('GOT launch_url '+launch_url);
    console.log('GOT tokn '+tokn);

    launch_url = launch_url.replaceAll("profile_token", tokn);
    console.log('GOT tokenized launch_url '+launch_url);
    var parameters = launch_url.replaceAll("https://themulembegardenslimited.prerelease-env.biz/gs2c/playGame.do?key=", "");
    var parameters_ = parameters.replaceAll("&stylename=tmgl_undabet", "");
    var url = "https://themulembegardenslimited.prerelease-env.biz/gs2c/playGame.do?key=" + encodeURIComponent(parameters_) + "&stylename=tmgl_undabet";
    console.log(url)

    return window.location.href = url;
    
    // return launch_url;
  }


}
