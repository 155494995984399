<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-tree-node class="tree-txt d-flex justify-content-between clickable-node" *matTreeNodeDef="let node" matTreeNodeToggle (click)="toggleCompetition(node.id, node.sport_id)">
        <div class="leffy">
            {{node.name}}
        </div>
        <div class="righty">
            <span [class.d-none]="node.count == null">
                {{node.count}}
            </span>
        </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [matNestedTreeNode]="node">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ expand(node) }}
            </mat-icon>
          </button>

          <span class="tree-txt" >{{node.name}}</span>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
            role="group">
          <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
