<section class="container-fluid py-2" >
    <div class="row-wrapper d-flex justify-content-around">
        <div class="w-15 lef-sec shadow-sm righ-side hide-mobile bg-light">
           
        </div>
        <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">

          <app-account-details></app-account-details>

            <section class="mybetssection" style="background-color: #fff;">
                <div class="topbar d-flex justify-content-left">
                    <div class="icon " [routerLink]="['/account']">
                        <i class="bi bi-arrow-left text-light"></i>
                    </div>
                    <div class="text-light txt ">
                        My Bets
                    </div>
                </div>
                <div class=" pt-2" style="">
                  <div class="tabbar d-flex justify-content-center">
                    <div class="highlights" [class.active]="(currentTab | async) === 'today'" (click)="currentTab.next('today'); getBets(0);">
                      Today
                    </div>
                    <div class="highlights" [class.active]="(currentTab | async) === 'week'" (click)="currentTab.next('week'); getBets(7);">
                      This week
                    </div>
                    <div class="highlights" [class.active]="(currentTab | async) === 'month'" (click)="currentTab.next('month'); getBets(30);">
                      This month
                    </div>
                    <div class="highlights" [class.active]="(currentTab | async) === 'all'" (click)="currentTab.next('all'); getBets();">
                      All bets
                    </div>
                  </div>
                </div>

                <app-shimmer [count]="3" [type]="'bet'" *ngIf="(shimmerLoading | async)"></app-shimmer>

                <div class="trans-wrapper bets-wrapper" *ngIf="!(shimmerLoading | async)">
                    <div class="item-div d-flex justify-content-left" *ngFor="let bet of (myBets | async)" [routerLink]="['/betdetail']" [state]="{bet: bet}">
                        <div class="left">
                            <span class="title">{{ getDate(bet.created, false) }}</span> <br>
                            <span class="date text-muted"> {{ getDate(bet.created, true) }} </span>
                        </div>

                        <div class="right text-success pt-1 w-100">
                            <div class="res d-flex justify-content-between" [ngClass]="bet.status === 1 ? 'res-active' : bet.status === 5 ? 'res-won' : ''">
                                <div class="type">
                                    {{ bet.bet_type }}
                                </div>
                                <div class="result">
                                    {{ bet.status === 1 ? 'Active' : bet.status === 5 ? 'Won' : 'Lost' }}
                                </div>
                            </div>

                            <div class="stake-data d-flex justify-content-between">
                                <div class="stake ">
                                    <div class="tit text-muted py-1">
                                        <span>
                                            Total Stake
                                        </span>
                                        <br>
                                        <span class="act-amount text-dark">
                                            {{ bet.bet_amount }}
                                        </span>
                                    </div>

                                </div>

                                <!-- income -->
                                <div class="stake ">
                                    <div class="tit text-muted py-1 text-right">
                                        <span>
                                            Total Winnings
                                        </span>
                                        <br>
                                        <span class="act-amount text-dark">
                                            {{ bet.possible_win }}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="w-20 shadow-sm righ-side bg-light hide-mobile">
            
        </div>
    </div>

</section>
