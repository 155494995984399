<section id="sportsNav">
    <div class="nav-wrapper">
        <div class="title-div">
            {{currentSport.sport_name}}
        </div>
        <div class="item-div " style="flex-shrink: 0;"  >
            {{ currentLeague?.category == null ? "All Matches" : currentLeague?.category }} {{ currentLeague?.name == null ? "" : "- " + currentLeague?.name }}
        </div>

    </div>

    <section>
        <app-search></app-search>
    </section>

    <div class="tabbar d-flex justify-content-center">
        <div class="highlights" [class.active]="currentTab == 'Highlights'" (click)="toggleTab('Highlights')">
            Top
        </div>
        <div class="highlights" [class.active]="currentTab == 'Today'" (click)="toggleTab('Today')">
            Today
        </div>
        <div class="highlights" [class.active]="currentTab == 'Tomorrow'" (click)="toggleTab('Tomorrow')">
            Tomorrow
        </div>

        <div class="highlights hide-desktop" [class.active]="currentTab == 'Countries'" (click)="toggleTab('Countries')">
            Sports
        </div>

    </div>
    <div class="tabbar-odds d-none">
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="1X2"></mat-tab>
            <mat-tab label="O/U"></mat-tab>
            <mat-tab label="DC"></mat-tab>
          </mat-tab-group>

    </div>

</section>
