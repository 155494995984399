import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'backend';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css']
})
export class DepositComponent implements OnInit {
  constructor(public authService: AuthService, public dialog: MatDialog) { }

  @ViewChild('closeModal') closeModal: ElementRef;
  auth: any;
  pollingData: any;
  depositForm = new FormGroup({
    amount: new FormControl(''),
  });

  amountInvalid = false;

  user;
  isLoading = false;
  errorMessage = '';
  successMessage = '';
  ngOnInit(): void {
    this.authService.authStream.subscribe(auth => {
      this.auth = auth;
    });
  }
  openDialog(title, message, status){
    this.dialog.open(DialogComponent, {data: { message: message , title: title, status: status}})

  }
  onSubmit(): void {

    this.isLoading = true;
    if (this.validateAmount(this.depositForm.value.amount) === true){
      this.authService.sendDepositPrompt(this.depositForm.value.amount, this.auth).subscribe(response => {
        console.log(response);
        this.closeModal.nativeElement.click();
        this.openDialog("Success", 'Success. A deposit prompt has been sent to your phone.', "success");
        this.isLoading = false;
        this.errorMessage = '';
      },
      error => {
        console.log(error);
        this.successMessage = '';
        this.errorMessage = 'An error occurred, please try again later';
        this.isLoading = false;
      });
    }else{
      this.isLoading = false;
      this.successMessage = '';
      this.errorMessage = 'Enter a valid amount';
    }
  }

  validateAmount(amount): boolean {
    if (amount > 4){
      return true;
    }else{
      this.amountInvalid = true;
      return false;
    }
  }

}
