import { Component, Input, OnInit } from '@angular/core';
import { SportsService } from 'backend';

@Component({
  selector: 'app-datewrapper',
  templateUrl: './datewrapper.component.html',
  styleUrls: ['./datewrapper.component.css']
})
export class DatewrapperComponent implements OnInit {

  constructor(private sportsService: SportsService) { }
  matchesList: any[] = [];
  @Input() matches: any[];
  @Input() title: any[];
  currentSport: any = {};
  ngOnInit(): void {
    this.sportsService.currentSportStream.subscribe(currentSport => {
      this.currentSport = currentSport;
    });
  }

}
