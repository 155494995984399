<div class="dialog text-center">
  <h1 mat-dialog-title [class.text-success]="data.status == 'success'" [class.text-info]="data.status == 'info'"
      [class.text-danger]="data.status == 'error'">{{ data.title }}</h1>
  <div mat-dialog-content>{{ data.message }}</div>
  <div mat-dialog-actions class="text-center mt-2 mb-1">
    <button mat-button mat-dialog-close [class.btn-success]="data.status == 'success'"
            [class.btn-info]="data.status == 'info'" [class.btn-danger]="data.status == 'error'"
            class="btn mx-auto btn-sm">Close
    </button>
  </div>
</div>
