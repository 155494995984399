import { Component, OnInit } from '@angular/core';
import { SportsService } from 'backend';

@Component({
  selector: 'app-livecasino',
  templateUrl: './livecasino.component.html',
  styleUrls: ['./livecasino.component.css']
})
export class LivecasinoComponent implements OnInit {

  constructor(private sportsService: SportsService) { }

  items: any[] = [];
  ngOnInit(): void {
    this.sportsService.fetchCasino(15).subscribe(result => {
      console.log(result);
      this.items = result.message[0].games;
    },err => {
      console.log(err)
    })
  }


}
