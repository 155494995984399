<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
        <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
            <app-sidebar></app-sidebar>
        </div>
        <div class=" w-65 shadow-sm mid-sec righ-side  bg-light jackpots" >
            <!-- <img src="/assets/banners/JACKPOT_BETWIN_2022.png" class="d-block w-100" alt="welcome to UNDABET JACKPOTS"> -->
            
            <mat-accordion class="desk" multi>
                <!-- start expansion panel -->
                <mat-expansion-panel *ngFor="let jackpot of jackpots " [expanded]="expand" >
                  <mat-expansion-panel-header>
                    <mat-panel-title class="bigger" style="font-size:15px; font-weight: 500">
                        <i  class="bi bi-circle mr-2" style="font-size: 15px;"></i> <span style="padding-left: 10px ;">{{ jackpot.value.name }}</span> 
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="league-section " >
                    <!-- Put jackpot here -->
                    <app-jackpot [jackpot_id]="jackpot.value.id"></app-jackpot>
                   </div>
                </mat-expansion-panel>
                 <!-- end expansion panel -->
            </mat-accordion>
        </div>
        <div class="w-20 shadow-sm righ-side bg-light hide-mobile sticky">
            <app-betslipjackpot></app-betslipjackpot>
        </div>
    </div>
    
</section>