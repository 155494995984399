<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
      <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
        <!-- <app-sidebar ></app-sidebar> -->
      </div>
      <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
        <app-carousel></app-carousel>
       
        <div class="casino-header text-light">
            Virtuals
        </div>

        <div class="card-casino-wrapper">
            <div class="row">
                <div class="col-md-2 col-4 m-0 p-0" *ngFor="let game of items">
                    <a href="getLaunchUrl(game.launch_url)">
                    <div class="casino-thumbnail">
                        <img [src]="game.image" alt="" style="width: 100%; height: 140px; border-radius: 2px;">
                    </div>
                    </a>
                </div>
                
            </div>
        </div>
        
      </div>
      <div class="w-20 shadow-sm righ-side bg-light hide-mobile sticky">
        
      </div>
    </div>
  
  </section>
  
