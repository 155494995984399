import { Component, OnInit } from '@angular/core';
import { AuthService, SportsService } from 'backend';

@Component({
  selector: 'app-virtuals',
  templateUrl: './virtuals.component.html',
  styleUrls: ['./virtuals.component.css']
})
export class VirtualsComponent implements OnInit {

  constructor(private sportsService: SportsService, private authService: AuthService) { }

  items: any[] = [];
  auth: any = null;
  ngOnInit(): void {
    this.sportsService.fetchCasino(14).subscribe(result => {
      console.log(result);
      this.items = result.message[0].games;
    },err => {
      console.log(err)
    })

    this.authService.authStream.subscribe(auth => {
        this.auth = auth;
    })
  }


  getLaunchUrl(launch_url){
    if(this.auth == null){
      return launch_url;
    }
    var profile = this.auth;
    var tokn = btoa(profile.profile_id);

    console.log('GOT launch_url '+launch_url);
    console.log('GOT tokn '+tokn);

    launch_url = launch_url.replaceAll("profile_token", tokn);
    console.log('GOT tokenized launch_url '+launch_url);

    return launch_url;
  }

}
