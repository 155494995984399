import { Component, OnInit } from '@angular/core';
import { SportsService } from 'backend';


/**
 * @title Tree with nested nodes
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {



  constructor(private sportsService: SportsService) {
    // this.dataSource.data = TREE_DATA;
  }

  

  isFirst= true;
  navData: any = [];
  currentSport:any = null;

  ngOnInit(): void {
    this.sportsService.navDataStream.subscribe((data) => {
      // console.log(data);
      if(Array.isArray(data)){
        this.navData = data;
        
      }
    });

    this.sportsService.currentSportStream.subscribe(sport => {
      this.currentSport = sport;
    })
  }


  getHasChild(sport)
  {
    
    return true;
  }


}
