<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
      <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
        
      </div>
      <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
        <div class="topbar d-flex justify-content-left">
            
            <div class="text-light txt ">
               Terms And Conditions
            </div>

           
        </div>
        <div class="">
            <app-carousel></app-carousel>
            
        </div>
        <div class="txt-bonus px-2 py-2">
        <p align=center style='text-align:center'><b><span lang=EN-GB style='font-size:
10.0pt'>TERMS AND CONDITIONS</span></b></p>

<p><span style='font-size:10.0pt'>These general terms and conditions
(&quot;terms and conditions&quot; or &quot;T &amp; C&quot;) shall be valid for
all betting games offered by </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>. By using our SMS and/or visiting any section of the
Website, or by opening an account with </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'>
through the Website, or SMS, you agree to be bound by the Terms and Conditions.</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> reserves the right to change these Terms and
Conditions at any time and without prior notice and will endeavour to inform
you about major modifications to the Terms and Conditions. We may need to
change the Terms of Service from time to time for a variety of reasons,
including (but not limited to) commercial reasons, compliance with law or
regulations, compliance with instructions, guidance, or recommendations from a
regulatory body, or customer service reasons.</span></p>

<p><span style='font-size:10.0pt'>The user is responsible in ensuring that they
don’t infringe on any laws in their jurisdiction when opening an account and
conducting business with </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> at all times during the subsistence of their
subscription/ participation.</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> will accept no liability from third parties
whatsoever, resulting from you providing incorrect or false data.</span></p>

<p><span style='font-size:10.0pt'>One customer may open only one account.
Should we identify any customer with more than one account, we reserve the
right to close these accounts. If </span><span lang=EN-GB style='font-size:
10.0pt'>Betfiti</span><span style='font-size:10.0pt'> detects multiple
accounts which have been set-up deliberately with misleading information, have
displayed criminal behaviour, or if </span><span lang=EN-GB style='font-size:
10.0pt'>Betfiti</span><span style='font-size:10.0pt'> concludes that the
account-owner has fraudulent intentions, </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'> shall
be entitled to close the accounts and confiscate all funds, including initial
deposit.</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> reserves the right to undertake the following:</span></p>

<ol start=1 type=1>
 <li class=MsoNormal><span style='font-family:"Times New Roman",serif'>Verifying
     your identity</span></li>
 <li class=MsoNormal><span lang=EN-GB style='font-family:"Times New Roman",serif'>V</span><span
     style='font-family:"Times New Roman",serif'>erifying your gaming activity</span></li>
 <li class=MsoNormal><span style='font-family:"Times New Roman",serif'>Conduct
     security and other internal procedures in relation to your account.</span></li>
 <li class=MsoNormal><span lang=EN-GB style='font-family:"Times New Roman",serif'>E</span><span
     style='font-family:"Times New Roman",serif'>nsure that prizes offered have
     been adhered to</span></li>
</ol>

<p><strong><span lang=EN-GB style='font-size:10.0pt'>DEFINITIONS</span></strong></p>

<p><span style='font-size:10.0pt'>In these rules, the following words have the
following meanings unless the context requires otherwise.</span></p>

<p><span style='font-size:10.0pt'>We, us, our means </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'>
(product) and our offices are at Mountain Mall, Thika Road.</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Bet: Means the staking of money on
the prediction of the outcome of a sporting event offered by us. </span></p>

<p><span style='font-size:10.0pt'>A bet can include one or more predictions. A
bet on one outcome is a single. A bet on many different outcomes is a multiple.</span></p>

<p><span style='font-size:10.0pt'>Odds or Price: the return on the money staked
if the prediction in a bet is correct.A winning price of 2 would return double
the stake to the customer.</span></p>

<p><span style='font-size:10.0pt'>Customer</span><span lang=EN-GB
style='font-size:10.0pt'>:</span><span style='font-size:10.0pt'> means anyone
placing a bet with </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> or any other service hosted or sponsored by said
company.</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>User: Means anyone using this
service</span></p>

<p><span style='font-size:10.0pt'>Full time (FT): means the point when the
official 90 minutes of a full match have elapsed plus any 'Injury Time' that
the referee chooses to add and shall NOT include scheduled extra time (two half
times of 15 minutes each), penalty shoot-outs etc..</span></p>

<p><span style='font-size:10.0pt'>VOID: means that, for one reason or another,
the bet is not acceptable to </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>. In these cases, this bet is settled at the price of
1. In the case of a single bet, this would mean the stake is returned to the
customer.</span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in'><span
style='font-family:Wingdings'>l<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>CUSTOMERS' IDENTITY</strong></p>

<p><span style='font-size:10.0pt'>Your mobile phone number is your identity.
The identity of customers is determined by their mobile phone numbers. We
strongly encourage all customers to sign up for identity programs administered
by the mobile phone companies. We use mobile phone numbers to uniquely identify
customers and their account details. If transactions are made via your phone by
someone who is not the registered user, the registered user will remain liable
for their transactions. In addition, we cannot do account transactions for
accounts which are not identical to the phone number. This means that you
cannot, for example, ask to be <strong>paid out on a different number</strong>&nbsp;other
than the number you have placed a bet on; you also <strong>cannot send money to
us and request that it be placed in a different account</strong>. In addition
to your phone number, your initial transaction will initiate the creation of a
virtual account in which all money added to the cause of betting will be held.</span></p>

<p><span style='font-size:10.0pt'>This is not a standard banking account and it
should not be used to keep money other than for the purpose of placing bets.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>l<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>AGE RESTRICTION</strong></p>

<p><strong><span style='font-size:10.0pt'>The law prohibits under 18-year-olds
from betting</span></strong><span style='font-size:10.0pt'>. We may at any time
ask you to confirm your age. You agree to hold us harmless if you deceive us or
misrepresent your age.<strong>Under-age clients will have their accounts closed
immediately and any balances forfeited</strong>. We may also suspend ANY
account for any reason and require you to bring identification to one of our
offices or agents to prove your age and identity.Individuals who suspect </span><span
lang=EN-GB style='font-size:10.0pt'>under-age</span><span lang=EN-GB
style='font-size:10.0pt'> </span><span style='font-size:10.0pt'>gambling are
encouraged to contact us immediately at +254 111013100 or via email at support@</span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'>.co.ke.</span></p>

<p class=MsoNormal style='margin-left:38.0pt;text-indent:-.25in'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>Account Security:</strong></p>

<p><span style='font-size:10.0pt'>Customers are the registered users of their
mobile number and account. Customers are responsible for the security of their
phone numbers, account details, and their mobile money accounts. We strongly
recommend that customers do not disclose any security-sensitive information to
any other person. In the event that an unauthorized person uses a customer's
phone for betting or money transactions to us, the registered user remains
responsible.</span></p>

<p><span style='font-size:10.0pt'>If necessary, we may also ask you to identify
yourself and collect your winnings at one of our locations.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>PRICES</strong></p>

<p><span style='font-size:10.0pt'>Customers will incur charges when using SMS
services. We keep these as low as possible, currently at Ksh. 2 for each SMS
you receive from us on any of the participating networks. The minimum bet
amount is Kshs. 49&nbsp;for single bets and multibets. Jackpot betting is at a
fixed stake of Kshs. 49 For the Midweek Jackpot and Ksh 99 on the Mega Jackpot.&nbsp;This
prices are at the discretion of the Organisation and Subject to change as will
the organisation deem right.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>BETS ACCEPTANCE AND VALIDATION</strong></p>

<p><span style='font-size:10.0pt'>All football bets will be settled on 90
minutes’ play (also referred to as Full-time and normal time). This denotes the
period of play which includes time added by the referee for injuries and other
stoppages. This does not include scheduled extra time, or penalty shoot-outs,
if played.</span></p>

<p><span style='font-size:10.0pt'>In matches where penalty shoot outs or extra
time are due to take place, all bets are settled on 90 minutes unless an
Outright price is specifically requested and confirmed at the time the bet is
placed.</span></p>

<p><span style='font-size:10.0pt'>Where </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'> has
not quoted prices on a match, any single bet on that match will be void and
treated as a non-runner in multiple bets.</span></p>

<p><span style='font-size:10.0pt'>If any team starts a 90-minute competitive
game with less than 11 players, all bets on that match will be made void.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>LATE BETTING</strong></p>

<p><span style='font-size:10.0pt'>Bets can only be placed on the outcome of a
future event, unless we are offering special 'betting in-running prices' for
that event. If </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> mistakenly accepts a bet for an event that has
already started, then the bet will be void and your stake returned, except in
the following circumstances:</span></p>

<p><span style='font-size:10.0pt'>Where bets are taken after the start of an
event, bets will stand if nothing has happened which would significantly alter
the chances of the selection winning or losing.</span></p>

<p><span style='font-size:10.0pt'>All bets placed after the result of an event
is known will be void, win or lose.</span></p>

<p><span style='font-size:10.0pt'>Internet bets include bets placed on any of
our remote channels, including our https://www.</span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'>.ke/website
and via SMS. In the case of such bets, the bet is struck when a bet id or
receipt exists in our back end systems or database. We may refuse to take bets
placed if they are deemed wrong or mistaken.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>CANCELLATION OF BET</strong></p>

<p><span style='font-size:10.0pt'>By placing a bet, you confirm your
wilful&nbsp;intention to conclude such a transaction. Therefore, once a bet has
been placed successfully, it cannot be cancelled.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>CORRECTIONS TO ACCOUNTS</strong></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> has the right to recover from you any amount overpaid
and has your authority to adjust your account to reflect the true outcome and
rectify the mistake. An example of such a mistake might be where a price is
incorrect, a bet is late (see Acceptance and Validation of Bets), where a
maximum payout limit has been reached and paid out erroneously or where we
enter a result of an event incorrectly.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>DISPUTES</strong></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti has the <b>Right </b> <b>to </b>
<b>Void </b> <b>Bets </b> <b>Win or Lose</b> <b> and return the initial stake</b>,
this might be due to but not limited to suspicion and system manipulation. The
company also<b> reserves the right to close your account without notice.</b></span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti <b>Reserves the right to
refuse/cancel any bet or part of a bet before, during and or after a game
starts and to make ambiguous bets void, WITHOUT PROVIDING ANY JUSTIFICSTION.</b></span></p>

<p><span style='font-size:10.0pt'>If you have a complaint, you can email
customer support at support@</span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>.ke and if your query or complaint is not resolved,
you can ask for the matter to be escalated to the Head of Betting Operations.
The Head of Betting Operations will investigate and contact you back with a
resolution within 48 hours. You will be given the name and status of the people
to whom your query/complaint has been referred. If you remain dissatisfied, we
may agree with you to refer the matter to an independent third body for
arbitration.</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> will always apply its best efforts to resolve a
reported matter promptly.</span></p>

<p><span style='font-size:10.0pt'>If you have a query with regard to any
transaction, you may also contact </span><span lang=EN-GB style='font-size:
10.0pt'>Betfiti</span><span style='font-size:10.0pt'> at support@</span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'>.ke with details of the query. </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'> will
review any queried or disputed transactions.</span></p>

<p><span style='font-size:10.0pt'>If for some reason you are not satisfied with
the resolution of the complaint by the Company, you can complain to the Betting
Control and Licensing Board (&quot;BCLB&quot;). Please keep in mind that by
contacting the BCLB, you are confirming that you have not violated any of the </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'> Terms and Conditions that you agreed to when you registered for our
service.</span></p>

<p><span style='font-size:10.0pt'>In all other cases, when a dispute arises,
the parties shall refer the matter to arbitration by a single arbitrator agreed
upon by the parties, with Nairobi as the chosen venue.Where there is no
agreement, each party shall appoint an arbitrator, and the nominated
arbitrators shall appoint a single arbitrator and</span></p>

<p><span style='font-size:10.0pt'>where they are unable to agree the Chairman
for the time being of the Kenyan Chapter of Chartered Institute of Arbitrators
shall appoint one.</span></p>

<p><span style='font-size:10.0pt'>Offensive or rude language, as well as
malicious or damaging comments, will not be tolerated while contacting our
staff or while discussing our products and services in any media, social
network or forum. Any infringement of this policy will result in a suspension
of the account or in additional actions as may be required in order to ensure
compliance.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>MISTAKES</strong></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> makes every effort to ensure that we do not make
errors in accepting bets. However, if as a result of human error or system
problems, a bet is accepted at a price (which includes the odds, handicap
provisions, and other terms and conditions or details of the bet), that is:</span></p>

<p><span style='font-size:10.0pt'>If the price is significantly different from
those available in the general market at the time the bet was placed; or
clearly incorrect given the likelihood of the event occurring at the time the
bet was placed, </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> will pay winnings at the correct price.</span></p>

<p><span style='font-size:10.0pt'>To establish the correct price, </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'> will consider prices available in the general market at the time the
bet was made, including the prices offered by other bookmakers operating in the
same market.</span></p>

<p><span style='font-size:10.0pt'>Examples of circumstances where this would
apply are:</span></p>

<ol start=1 type=1>
 <li class=MsoNormal><span style='font-family:"Times New Roman",serif'>the
     price is recorded as 100-1 when the price on offer in the general market
     is 10-1</span></li>
 <li class=MsoNormal><span style='font-family:"Times New Roman",serif'>The
     margins for handicap betting have been reversed.</span></li>
</ol>

<p><span style='font-size:10.0pt'>If a bet is accepted in error by </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'> on an event or outcome for which no </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'> prices
are available, the bet will be void and your stake returned.</span></p>

<p><span style='font-size:10.0pt'>A bet accepted on the website or via SMS does
not constitute a legally binding contract between the customer and us. The
prices we offer on all outcomes on display on this website take the form of an
advertisement rather than a contractual term. If we offer mistaken prices, then
we are entitled to decline any bet placed at those prices at any time. In such
instances, we will void the bet and return the stake money.</span></p>

<p><span style='font-size:10.0pt'>Any results or scores displayed on the site,
for example, during betting in play, are for guidance purposes only.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>FRAUD </strong></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> will seek criminal and contractual sanctions against
any customer involved in fraud, dishonesty or criminal acts. </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'> will withhold payment (including but not limited to possible payouts
and bet amount) to any customer where any of these are suspected.</span></p>

<p><span style='font-size:10.0pt'>The customer shall indemnify and shall be
liable to pay to </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>, on demand, all costs, charges or losses sustained or
incurred by </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> (including any direct, indirect or consequential
losses, loss of</span></p>

<p><span style='font-size:10.0pt'>profit and loss of reputation) arising
directly or indirectly from the customer’s fraud, dishonesty or criminal act.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>P</strong><strong><span lang=EN-GB>RICE</span><span
lang=EN-GB> </span></strong><strong><span lang=EN-GB>CHANGES</span></strong></p>

<p><span style='font-size:10.0pt'>All prices are subject to change and may, on
occasion, be restricted to certain stake levels. The prices offered via our
different business channels may vary.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>Results:</strong></p>

<p><span style='font-size:10.0pt'>In the case of football and other sports,
bets are settled on the official result published by the relevant event
managers or result handlers 3 to 24 hours after the match/event is finished.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>VOID</strong><strong><span lang=EN-GB> BET</span></strong></p>

<p><span style='font-size:10.0pt'>&quot;Void Bet&quot; means the bet is null or
invalid. This occurs when an event is postponed/cancelled, or when it has
started but not finished within the period specified in our policy. If a game
has been cancelled or postponed, there is always a 24-hour wait until the match
will be set as void. Once the match has been set as void (with an odd of 1.00),
the rest of the winning ticket will then be paid out.</span></p>

<p><span style='font-size:10.0pt'>If a selection in a single bet is made void,
the stake will be returned.</span></p>

<p><span style='font-size:10.0pt'>Void selections in multiple bets will be
treated as non-runners and the stake will run onto the remaining selections in
the bet.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>WITHHOLDING PAYMENT AND OUR RIGHT TO SET LIABILITY </strong></p>

<p><span style='font-size:10.0pt'>We reserve the right to withhold payment and
to declare bets on an event void if we have evidence that the following has occurred:</span></p>

<p><span style='font-size:10.0pt'>-The integrity of the event has been called
into question.</span></p>

<p><span style='font-size:10.0pt'>-the price(s) or pool has been manipulated</span></p>

<p><span style='font-size:10.0pt'>-Match rigging has taken place.</span></p>

<p><span style='font-size:10.0pt'>Evidence of the above may be based on the
size, volume or pattern of bets placed with us across any or all of our betting
channels. A decision given by the relevant governing body of the sport in
question (if any) will be conclusive. If any customer owes any money to </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'> for any reason, we have the right to take that into account before
making any payments to that customer.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>90-MINUTE FOOTBALL</strong><strong><span lang=EN-GB> BETS</span></strong></p>

<p><span style='font-size:10.0pt'>What is meant by '90-minute Betting', 'Full
Time' and 'Normal Time'?</span></p>

<p><span style='font-size:10.0pt'>Adult football matches are played over a
90-minute period, and this period can be called '90-minutes', 'Full Time' or
'Normal Time'. All football bets, unless specifically stating they are for
Outright, To Qualify, Extra Time or Penalties markets, will be settled on the
result after Normal Time (90 minutes).</span></p>

<p><span style='font-size:10.0pt'>During the game, the referee may stop the
game for short periods for substitutions, or if a player gets injured, and at
the end of each 45-minute half the referee adds however many minutes he feels
is required to account for any of these stoppages. This additional time is
called &quot;Injury time&quot;.</span></p>

<p><span style='font-size:10.0pt'>Full Time and Normal Time are terms for the full
90-minute period, plus any 'Injury Time' that the referee chooses to add. Full
Time and Normal Time DO NOT include Extra Time.</span></p>

<p><span style='font-size:10.0pt'>Some matches may have a FT period of eighty
(80) minutes or less, as per e.g. FIFA rules for such matches include U/16,
U/17, Women Seniors, and so on.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>ABANDONED MATCHES</strong></p>

<p><span style='font-size:10.0pt'>If a match is abandoned after it has
commenced, all bets on that match will be made void except where settlement has
already been determined. For example, where the first goal has been scored by a
named player, the First Goal Scorer and Time of First Goal markets, amongst
others, will stand.</span></p>

<p style='margin-left:21.0pt;text-indent:-21.0pt'><strong><span lang=EN-GB
style='font-size:10.0pt;font-family:Wingdings;font-weight:normal'>Ø<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></strong><strong><span
lang=EN-GB style='font-size:10.0pt'>POSTPONED/RE-ARRANGED MATCHES</span></strong></p>

<p><span style='font-size:10.0pt'>A postponed match is void unless it is
re-scheduled to commence within 24 hours of the original start time and this is
confirmed within 12 hours of the original start time. In such circumstances
where (a) void match(es) is/are included in an accumulator the bet will be
settled on the remaining selections.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>PRICES ARE SUBJECT TO CHANGE</strong><strong><span
lang=EN-GB>/ FLUCTUATE</span></strong></p>

<p><span style='font-size:10.0pt'>All prices are subject to fluctuation up to
the kick-off. All football bets will be settled using </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'>
on-line (https://www.</span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>.ke/) prices at the time the bet is placed.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong><span lang=EN-GB>RELATED </span>CONTINGENCIES IN </strong><strong><span
lang=EN-GB>ONE</span> MATCH</strong></p>

<p><span style='font-size:10.0pt'>Where we provide multiple betting options
from the same match (e.g., correct score, first player to score, etc....),
these cannot be combined into accumulative bets where the outcome is related
(except where special fixed prices are available).</span></p>

<p><span style='font-size:10.0pt'>Where an accumulative bet of this type has
been accepted in error, it will be settled by equally dividing the stake unit
where the related outcomes clash.</span></p>

<p style='margin-left:21.0pt;text-indent:-21.0pt'><strong><span lang=EN-GB
style='font-size:10.0pt;font-family:Wingdings;font-weight:normal'>Ø<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></strong><strong><span
lang=EN-GB style='font-size:10.0pt'>RESULTS</span></strong></p>

<p><span style='font-size:10.0pt'>If the result is not immediately available
from an official channel, then the result published by the official governing
body immediately the match/event is finished will be used for settling purposes.</span></p>

<p><span style='font-size:10.0pt'>Any subsequent corrections or amendments to
this result will not apply for settlement purposes. </span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'> will
settle all bets rapidly, but because of time differences, for some events, bets
may be settled overnight or with some delay.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>PAYOUT MAXIMUM</strong></p>

<p class=MsoNormal><span lang=EN-GB style='font-family:"Times New Roman",serif'>(a)</span><span
style='font-family:"Times New Roman",serif'>The minimum betting amount for a
single/multi bet is KSH49.</span></p>

<p><span style='font-size:10.0pt'>(b) Maximum Bet Amount: The maximum betting
amount for a single/multi bet is ksh20,000.</span></p>

<p><span style='font-size:10.0pt'>(c) Maximum Single Bet Winning: The single
bet winning amount is limited to ksh500,000.</span></p>

<p><span style='font-size:10.0pt'>(d) Maximum Multi-Bet Winning: The maximum
Multi-Bet winning amount is KSH500,000.</span></p>

<p><span style='font-size:10.0pt'>(e) Maximum Aggregate Winning (maximum
payout): The Maximum winnings amount per customer per day is limited to
ksh70,000</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>ACCOUNT SUSPENSION/FREEZE</strong></p>

<p><span style='font-size:10.0pt'>We reserve the right to close/freeze any
customer account or refuse to take bets from any customer. In this event of
account closure, the full value of any customer funds will be returned as soon
as possible.</span></p>

<p><span style='font-size:10.0pt'>Should you attempt to withdraw funds that
were deposited but not used for wagering, the company may levy a processing fee
of 10% for such withdrawals. Additionally, should these transactions be deemed
suspicious, the company will report the activity to the appropriate authorities
and the player may lose these funds.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>BETTING PROCEDURES </strong></p>

<p><strong><span style='font-size:10.0pt'>Anyone above 18years of age</span></strong><span
style='font-size:10.0pt'>&nbsp;can play as many times as they desire but within
the daily limits on bet values and payouts set aside in our Terms and
Conditions&nbsp;</span></p>

<p><strong><span style='font-size:10.0pt'>Registration: </span></strong><span
style='font-size:10.0pt'>Simply SMS &quot;</span><span lang=EN-GB
style='font-size:10.0pt'>Betfiti</span><span style='font-size:10.0pt'>&quot;
to <strong>238020 </strong>. You will receive a confirmation message from&nbsp;<strong>238020
</strong>confirming that you are now registered and ready to bet.</span></p>

<p><b><span style='font-size:10.0pt'>To top up your </span></b><b><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span></b><b><span
style='font-size:10.0pt'> Account</span></b><span style='font-size:10.0pt'>, go
to the Mpesa Menu, Lipa na Mpesa, go to pay bill number, enter business number
290060, account number </span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>, and enter the amount you wish to deposit. You can
also deposit on-line at www.</span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>.ke. Once you’ve logged in, click on ‘Deposit’, enter
the amount, and then click on ‘Top Up Now’.</span></p>

<p><strong><span style='font-size:10.0pt'>Balance: </span></strong><span
style='font-size:10.0pt'>To check your balance, SMS the word BALANCE to&nbsp;<strong>238020
</strong>. To check your bonus balance, SMS the word BONUS to&nbsp;<strong>238020
</strong>.</span></p>

<p><strong><span style='font-size:10.0pt'>Placing Bets: </span></strong></p>

<p><span style='font-size:10.0pt'>The minimum bet amount is Ksh 49&nbsp;and the
maximum limit is set in the terms and conditions.</span></p>

<p><span style='font-size:10.0pt'>There are three possible results in any
football match:</span></p>

<p><span style='font-size:10.0pt'>1 - Home Team wins</span></p>

<p><span style='font-size:10.0pt'>X - Draw result</span></p>

<p><span style='font-size:10.0pt'>The 2-Away Team triumphs.</span></p>

<p><span style='font-size:10.0pt'>To bet on your team, SMS GAMES to <strong>238020
</strong>and you will receive current football match fixtures and their ODDS.</span></p>

<p><span style='font-size:10.0pt'>You can also visit the </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'> website https://www.</span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>.ke/&nbsp;and identify the game you wish to bet on.</span></p>

<p><strong><span style='font-size:10.0pt'>Types of bets: </span></strong></p>

<p><strong><span style='font-size:10.0pt'>Single Bet: </span></strong><span
style='font-size:10.0pt'>Place bet in this format; GAMEID#PICK#AMOUNT</span></p>

<p><span style='font-size:10.0pt'>ODDS (1=2.54 X=3.47 2=3.10) GAMEID 123
Arsenal vs Liverpool</span></p>

<p><span style='font-size:10.0pt'>If you want to bet on this match, SMS 123# 1
# 200 to <strong>238020 </strong></span></p>

<p><strong><span style='font-size:10.0pt'>Multi Bet: </span></strong><span
style='font-size:10.0pt'>Place bet in this format;
GAMEID#PICK#GAMEID#PICK#AMOUNT</span></p>

<p><span style='font-size:10.0pt'>e.g. GAMEID 123 Arsenal vs Liverpool ODDS
(1=2.54 X=3.47 2=3.10)</span></p>

<p><span style='font-size:10.0pt'>ODDS (1=2.87 X=4.13 2=3.90) Manchester United
vs Chelsea GAMEID 456</span></p>

<p><span style='font-size:10.0pt'>SMS 123# 1 # 456 # X # 200 to 238020 to place
a bet on this match.</span></p>

<p><strong><span style='font-size:10.0pt'>Double Chance (DC): </span></strong><strong><span
lang=EN-GB style='font-size:10.0pt'>Possible Outcomes are- 12</span></strong><strong><span
style='font-size:10.0pt'>, 1X, and X2.</span></strong><span style='font-size:
10.0pt'>DC12, DC1X, DCX2 Formats</span></p>

<p><span style='font-size:10.0pt'>GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54
X=3.47 2=3.10)</span></p>

<p><span style='font-size:10.0pt'>Single Bet-Place a bet in this format: GAMEID
# PICK # AMOUNT GAMEID # PICK # AMOUNT GAMEID # PICK #</span></p>

<p><span style='font-size:10.0pt'>For example, 123# DC1X # 200</span></p>

<p><span style='font-size:10.0pt'>Arsenal vs. Liverpool ODDS (1=2.54 X=3.47
2=3.10)</span></p>

<p><span style='font-size:10.0pt'>ODDS (1=2.87 X=4.13 2=3.90) Manchester United
vs Chelsea GAMEID 456</span></p>

<p><span style='font-size:10.0pt'>Multi Bet - Place bet in this format:
GAMEID#PICK#AMOUNT</span></p>

<p><span style='font-size:10.0pt'>For example, 123# DC1X # 456 # DC12 # 200</span></p>

<p><strong><span style='font-size:10.0pt'>Goal Goal (GG) </span></strong><span
style='font-size:10.0pt'>Both teams score</span></p>

<p><strong><span style='font-size:10.0pt'>No Goal (NG) </span></strong><span
style='font-size:10.0pt'>No team scores:</span></p>

<p><span style='font-size:10.0pt'>GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54
X=3.47 2=3.10)</span></p>

<p><span style='font-size:10.0pt'>Single Bet-Place a bet in this format: GAMEID
# PICK # AMOUNT GAMEID # PICK # AMOUNT GAMEID # PICK #</span></p>

<p><span style='font-size:10.0pt'>E.g. 123#GG#200</span></p>

<p><span style='font-size:10.0pt'>123#NG#200</span></p>

<p><span style='font-size:10.0pt'>GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54
X=3.47 2=3.10)</span></p>

<p><span style='font-size:10.0pt'>GAMEID 456 Manchester United vs Chelsea ODDS
(1=2.87 X=4.13 2=3.90)</span></p>

<p><span style='font-size:10.0pt'>Multi Bet - Place bet in this format:
GAMEID#PICK#AMOUNT</span></p>

<p><span style='font-size:10.0pt'>E.g. 123#GG#456#NG#200</span></p>

<p><strong><span style='font-size:10.0pt'>Over/Under 1.5/2.5:</span></strong></p>

<p><strong><span style='font-size:10.0pt'>Over 2.5 (ov25) </span></strong><span
style='font-size:10.0pt'>over 2 goals scored</span></p>

<p><strong><span style='font-size:10.0pt'>Under 2.5 (un25), </span></strong><span
style='font-size:10.0pt'>under 2 goals scored.</span></p>

<p><span style='font-size:10.0pt'>GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54
X=3.47 2=3.10)</span></p>

<p><span style='font-size:10.0pt'>Single Bet-Place a bet in this format: GAMEID
# PICK # AMOUNT GAMEID # PICK # AMOUNT GAMEID # PICK #</span><span lang=EN-GB
style='font-size:10.0pt'>AMOUNT</span></p>

<p><span style='font-size:10.0pt'>200, 123# ov25 # 200, 123# un25 # 200</span></p>

<p><span style='font-size:10.0pt'>Arsenal vs. Liverpool ODDS (1=2.54 X=3.47
2=3.10)</span></p>

<p><span style='font-size:10.0pt'>ODDS (1=2.87 X=4.13 2=3.90) Manchester United
vs Chelsea GAMEID 456</span></p>

<p><span style='font-size:10.0pt'>Multi Bet-Place bet in this format: GAMEID #
PICK # AMOUNT GAMEID # PICK # AMOUNT GAMEID # PICK #</span><span lang=EN-GB
style='font-size:10.0pt'>AMOUNT</span></p>

<p><span lang=EN-GB style='font-size:10.0pt'>E.g 123#ov25#456#un25#200</span></p>

<p><strong><span style='font-size:10.0pt'>Half-time vs. Full-time</span></strong></p>

<p><span style='font-size:10.0pt'>GAMEID#HtFt(Halftime Pick Fulltime
Pick)#AMOUNT</span></p>

<p><span style='font-size:10.0pt'>Arsenal vs. Liverpool ODDS (1=2.54 X=3.47 2=3.10)</span></p>

<p><span style='font-size:10.0pt'>100 # HtFtX1</span><span lang=EN-GB
style='font-size:10.0pt'>#</span><span style='font-size:10.0pt'>123</span></p>

<p><strong><span style='font-size:10.0pt'>Winnings </span></strong></p>

<p><span style='font-size:10.0pt'>The Midweek and Mega Jackpot winner(s) will
share the&nbsp;guaranteed&nbsp;price. If there is no winner, then the jackpot
prize will be increased as per company policies on the jackpot.</span></p>

<p><strong><span style='font-size:10.0pt'>Winners’ Notification: </span></strong><span
style='font-size:10.0pt'>Winners will receive an SMS notification and a follow
up call advising on their winnings and&nbsp;they will be advised on way of
payment and disbursement.</span></p>

<p><b><span style='font-size:10.0pt'>To withdraw</span></b><span
style='font-size:10.0pt'>, send W# AMOUNT to <strong>238020</strong>. You can
also withdraw from https://www.</span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>.ke/. Once you are logged in, click on withdraw, enter
the amount, and click on &quot;withdraw now&quot;. The minimum withdrawal
amount is Kshs. 50. Kindly note that you are not able to withdraw your bonus. </span></p>

<p><strong><span style='font-size:10.0pt'>Note: Users will be charged standard
withdrawal charges when they withdraw funds deposited without placing a bet on
the website. </span></strong></p>

<p style='margin-left:21.0pt;text-indent:-21.0pt'><span lang=EN-GB
style='font-size:10.0pt;font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><b><span lang=EN-GB style='font-size:10.0pt'>BONUS</span></b></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> bonus scheme is currently available for the following
users:</span></p>

<ol start=1 type=1>
 <li class=MsoNormal><span style='font-family:"Times New Roman",serif'>Apart
     from the jackpot bonus, any other bonus awarded cannot be withdrawn.
     However, bonus winnings derived from the bets placed successfully with the
     bonus can be withdrawn.</span></li>
</ol>

<p style='margin-left:21.0pt;text-indent:-21.0pt'><span style='font-size:10.0pt;
font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><strong><span
lang=EN-GB style='font-size:10.0pt'>BET </span></strong><strong><span
style='font-size:10.0pt'>Limits</span></strong></p>

<p class=MsoNormal style='margin-left:.25in;text-indent:0in'><span
style='font-family:"Times New Roman",serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Times New Roman",serif'>A number of
bets may be treated as being one when a client places multiple copies of the
same bet. When this occurs, all bets may be voided apart from the first bet
struck. A number of bets that contain the same single selection may be treated
as being one. When this occurs, all bets may be voided apart from the first bet
struck. An example would be where one particular selection is repeatedly
included in multiple bets involving other short-priced selections.</span></p>

<p class=MsoNormal style='margin-left:.25in;text-indent:0in'><span
style='font-family:"Times New Roman",serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Times New Roman",serif'>Where there is
evidence of a series of bets each containing the same (or very similar)
selection(s) having been placed by or for the same individual or syndicate or
individuals, the company reserves the right to make bets void.</span></p>

<p class=MsoNormal style='margin-left:.25in;text-indent:0in'><span
style='font-family:"Times New Roman",serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Times New Roman",serif'>If you have
played in a professional capacity, or in tandem with other player(s) as part of
a club, group, etc..., or placed bets or wagers in a coordinated manner with
other player(s) involving the same (or materially the same) selections; in this
instance we further reserve the right, at our sole discretion, to restrict the
total maximum pay out for the combined total of any such bets, to the
equivalent of the maximum payout limits permitted for a single customer (as
outlined in the Company’s general Terms and Conditions). </span><span
lang=EN-GB style='font-family:"Times New Roman",serif'>Betfiti</span><span
style='font-family:"Times New Roman",serif'> is for sole use on an individual
basis and for personal entertainment only.</span></p>

<p class=MsoNormal style='margin-left:21.0pt;text-indent:-21.0pt'><span
style='font-family:Wingdings'>Ø<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><strong>ALTERATION OF THE WEBSITE</strong></p>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> may, in our absolute discretion, alter or amend any
product or service (including any prices offered) available through the website
(https://www.</span><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'>.ke/) at any time for the purpose of ensuring the
ongoing provision of the website, but without prejudice to any games and/or
bets already in progress at the time of such amendment. From time to time, we
may restrict you from accessing some parts of the website for the purpose of
maintenance of the website and/or alteration or amendment of any of the games
and/or products available through the website.</span></p>

<p><em><b><span style='font-size:10.0pt'>General Disclaimer</span></b></em></p>

<p><span style='font-size:10.0pt'>The purpose of this website is to provide
information about the live betting market.&nbsp;</span></p>

<p><span style='font-size:10.0pt'>Nothing on this website should be regarded as
either betting advice or encouragement to engage in live betting.</span></p>

<p><span style='font-size:10.0pt'>Gambling is very risky and careless gambling
can result in the loss of substantial sums of money.</span></p>

<p><strong><span style='font-size:10.0pt'>We provide absolutely no guarantees, either
expressly or implied, that using the information on this site will either help
you make money, or prevent you from losing money.</span></strong></p>

<p><span style='font-size:10.0pt'>No person or entity associated with this
website shall be liable for any losses (monetary or otherwise) incurred as a result
of the use or misuse of the information on this website.</span></p>

<p><span style='font-size:10.0pt'>Viewing any page on the </span><span
lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span style='font-size:
10.0pt'>.ke website indicates that you have read, understood and accepted the
terms and conditions stated above.</span></p>

<p><strong><span style='font-size:10.0pt'>Under-age clients will have their
accounts closed immediately and any balances forfeited</span></strong></p>

<ol start=1 type=1>
 <li class=MsoNormal><strong>PROMOTIONAL SMS</strong></li>
</ol>

<p><span lang=EN-GB style='font-size:10.0pt'>Betfiti</span><span
style='font-size:10.0pt'> sms’ are meant for transactional purposes whereby
they come automated as a response. But for any promotional sms’ to opt out sms
STOP to <b>238020</b></span></p>
  
  <!-- <p class=MsoNormal><span>&nbsp;</span></p>
  <p class=MsoNormal align=center style='text-align:center'><b><span 
    >MEGA MILLI JACKPOT</span></b></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l2 level1 lfo1'><b><span
     ><span >A) </span></span></b><b><span
     >MEGA JACKPOT BET</span></b></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >1. </span></span><span
     ><span
    style='mso-spacerun:yes'> </span>The Mega Milli Jackpot is Subject to change on
    a weekly basis. </span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >2. </span></span><span
     >The Mega Milli Jackpot is based on
    seventeen (17) pre-selected football events.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >3. </span></span><span
     >You become the Mega Milli Jackpot
    winner if you predict all the seventeen (17) pre-selected football games
    correctly.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >4. </span></span><span
     >The Mega Milli Jackpot amount will
    be divided equally amongst all the Mega Milli Jackpot winners. </span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >5. </span></span><span
     >The Company reserves the right to
    withhold any price share until the Price Presentation Ceremony. </span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >6. </span></span><span
     >The Company reserves the right to
    pay the whole amount of any price share to a winner by cheque or Bank Transfer.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >7. </span></span><span
     >The company reserves the right to
    use the names, video, audio, radio or other recordings, motion and still images
    of the winner for purposes of publicity and marketing campaigns.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >8. </span></span><span
     >As part of this payment process,
    winners will be asked to provide an acceptable proof <span class=GramE>of<span
    style='mso-spacerun:yes'>  </span>identity</span> before any payment is made.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >9. </span></span><span
     >Where a Mega Milli Jackpot game is
    cancelled an official <b>PUBLIC DRAW</b> shall be carried out after twenty-four
    (24) hours from the time of cancellation, to determine the result of the
    missing game if that match <span class=SpellE><span class=GramE>wont</span></span>
    be played in the next 24 hours from the official time that it was supposed to
    play.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >10. </span></span><span
     >The Period for claiming the price is
    seven (7) days failure to which the company may deem the price forfeited,
    unless the period is extended in writing at the sole discretion of the company.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l1 level1 lfo2'><span
     ><span >11. </span></span><span
     >Where three or more games are
    cancelled, interrupted, abandoned, suspended or postponed the company may at
    its discretion CANCELL the Mega Milli Jackpot and refund the stake placed
    within twenty-four (24) hours of the Cancellation. </span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l2 level1 lfo1'><b><span
     ><span >B) </span></span></b><b><span
     >MEGA JACKPOT BONUS</span></b></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l0 level1 lfo3'><span
     ><span >1. </span></span><span
     >Mega Milli Jackpot Players are
    Eligible to additional prices such as The Mega Jackpot Bonus.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l0 level1 lfo3'><span
     ><span >2. </span></span><span
     >The Mega Jackpot Bonus amount shall
    be determined by the <span class=GramE>company<span style='mso-spacerun:yes'> 
    </span>at</span> its discretion.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l0 level1 lfo3'><span
     ><span >3. </span></span><span
     >The Mega Milli Jackpot Bonus amount
    shall vary between various classes.</span></p>
    
    <p class=MsoNormal style='margin-left:0in;text-indent:0in;mso-list:l0 level1 lfo3'><span
     ><span >4. </span></span><span
     >The Mega Milli Jackpot Bonus amount
    shall be divided equally among the Jackpot bonus winners, within their
    respective classes.</span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal align=center style='text-align:center'><span class=GramE><b><span
     >MIDWEEK<span
    style='mso-spacerun:yes'>  </span>JACKPOT</span></b></span><span 
    ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span class=GramE><b><span  style='mso-ansi-language:
    EN-GB'>MIDWEEK<span style='mso-spacerun:yes'>  </span>JACKPOT</span></b></span><b><span
     > BET</span></b><span 
    ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span  >1. The
    Midweek Jackpot is Subject to change on a weekly basis. </span></p>
    
    <p class=MsoNormal><span  >2.The
    Midweek Jackpot is based on Thirteen (13) pre-selected football events.</span></p>
    
    <p class=MsoNormal><span  >3.You
    become the Midweek Jackpot winner if you predict all the Thirteen (13)
    pre-selected football games correctly.</span></p>
    
    <p class=MsoNormal><span  >4.The
    Midweek Jackpot amount will be divided equally amongst all the Midweek Jackpot
    winners.</span></p>
    
    <p class=MsoNormal><span  >5.The
    Company reserves the right to withhold any price share until the Price
    Presentation Ceremony.</span></p>
    
    <p class=MsoNormal><span  >6.The Company
    reserves the right to pay the whole amount of any price share to a winner by
    cheque or Bank Transfer.</span></p>
    
    <p class=MsoNormal><span  >7.The
    company reserves the right to use the names, video, audio, radio or other
    recordings, motion and still images of the winner for purposes of publicity and
    marketing campaigns.</span></p>
    
    <p class=MsoNormal><span  >8.As part
    of this payment process, winners will be asked to provide an acceptable proof <span
    class=GramE>of<span style='mso-spacerun:yes'>  </span>identity</span> before
    any payment is made.</span></p>
    
    <p class=MsoNormal><span  >9.Where a
    Midweek Jackpot game is cancelled an official <b>PUBLIC DRAW </b>shall be
    carried out after twenty-four (24) hours from the time of cancellation, to
    determine the result of the missing game if that match <span class=SpellE><span
    class=GramE>wont</span></span> be played in the next 24 hours from the official
    time that it was supposed to play.</span></p>
    
    <p class=MsoNormal><span  >10.The
    Period for claiming the price is seven (7) days failure to which the company
    may deem the price forfeited, unless the period is extended in writing at the
    sole discretion of the company.</span></p>
    
    <p class=MsoNormal><span  >11.Where
    three or more games are cancelled, interrupted, abandoned, suspended or
    postponed the company may at its discretion CANCELL the Midweek Jackpot and
    refund the stake placed within twenty-four (24) hours of the Cancellation.</span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><b><span  >MIDWEEK
    JACKPOT BONUS</span></b><span  ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span  >1.Midweek
    Jackpot Players are Eligible to additional prices such as The Midweek Jackpot
    Bonus</span></p>
    
    <p class=MsoNormal><span  >2.The
    Midweek Jackpot Bonus amount shall be determined by the <span class=GramE>company<span
    style='mso-spacerun:yes'>  </span>at</span> its discretion among various
    classes.</span></p>
    
    <p class=MsoNormal><span  >4.The
    Midweek Jackpot Bonus amount shall vary between the various classes </span></p>
    
    <p class=MsoNormal><span  >5.The
    Midweek Jackpot Bonus amount shall be divided equally among the Jackpot bonus
    winners, within their respective classes </span></p>
    
    <p class=MsoNormal><span  >6.Where
    three or more games are cancelled, <span class=GramE>interrupted,<span
    style='mso-spacerun:yes'>  </span>abandoned</span>, suspended or postponed, the
    company may at its discretion<span style='mso-spacerun:yes'>  </span>cancel the
    Mega Jackpot Bonus<span style='mso-spacerun:yes'>  </span>and refund the stake
    amount within twenty-four (24) hours of the cancellation.</span></p>
     -->
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
    <p class=MsoNormal><span  ></span></p>
    
        </div>
        
        
      </div>
      <div class="w-20 shadow-sm righ-side bg-light hide-mobile">
       
      </div>
    </div>
  
  </section>
  