
<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
        <div class="w-15 lef-sec shadow-sm righ-side  bg-light hide-mobile">
           
        </div>
        <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
            <!-- Account details -->
            <app-account-details></app-account-details>
            <div class="item-wrapper">
                <ul class="list-group">
                    <li class="list-group-item" [routerLink]="['/bets']">
                        <div class="items-wrapp d-flex justify-content-between">
                            <div class="text">
                                My Bets
                            </div>
                            <div class="icon">
                                <i  class="bi bi-chevron-compact-right"></i>
                            </div>
                        </div>

                    </li>
                    <li class="list-group-item" [routerLink]="['/transactions']">
                        <div class="items-wrapp d-flex justify-content-between">
                            <div class="text">
                                My Transactions
                            </div>
                            <div class="icon">
                                <i  class="bi bi-chevron-compact-right"></i>
                            </div>
                        </div>
                    </li>
                  </ul>
            </div>
            <div class="mt-3 mb-3">
                <button class="btn btn-light form-control " style="border-radius: 0; font-size: 13px;" (click)="logOut()">Logout</button>
            </div>

        </div>
        <div class="w-20 shadow-sm righ-side bg-light hide-mobile">
           
        </div>
    </div>

</section>
