<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
        <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
            <div class="topbar d-flex justify-content-left">
                <div class="icon " [routerLink]="['/']">
                    <i class="bi bi-arrow-left text-light"></i>
                </div>
                <div class="text-light txt ">
                   Countries 
                </div>
            </div>
            <app-sidebar></app-sidebar>
            
        </div>
        
    </div>
    
</section>