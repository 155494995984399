<section>
  <div class="header-strip">
    <div class="meta">
      {{ title }}
    </div>
    <div class="template odd-section-left" >
      <div class="template-child col-4" [class.col-6]="currentSport.type == '2way'">
        1
      </div>
      <div class="template-child col-4" *ngIf="currentSport.type == '3way'">
        X
      </div>
      <div class="template-child col-4" [class.col-6]="currentSport.type == '2way'">
        2
      </div>
    </div>
    <div class="template odd-section-left hide-mobile" *ngIf="currentSport.type == '3way'">
      <div class="template-child col-4">
        1/X
      </div>
      <div class="template-child col-4">
        X/2
      </div>
      <div class="template-child col-4">
        1/2
      </div>
    </div>
    <div class="template odd-section-left hide-mobile" *ngIf="currentSport.sport_name == 'Soccer'">
      <div class="template-child col-6">
        OV 2.5
      </div>
      <div class="template-child col-6">
        UN 2.5
      </div>
      
    </div>
    <div class="template hide-mobile" *ngIf="currentSport.sport_name == 'Soccer'">
      <div class="template-child col-6">
        GG
      </div>
      <div class="template-child col-6">
        NG
      </div>
      
    </div>

  </div>

  <app-gamecard *ngFor="let match of matches" [match]="match" [currentSport]="currentSport"></app-gamecard>
</section>
