<section class="betslip">
    <button #openLoginModal class="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Log In</button>
    <div class="header-betslip-sec text-center d-flex justify-content-between">
        <div class="leff">
            <span class="big-poppi">{{ betslip.length }}</span> Betslip
        </div>
        <div class="cent text-center hide-desktop" style="font-size: 20px;" (click)="hideBetslip()">
            <i class="bi bi-chevron-compact-down"></i>
        </div>
        <div class="rii" *ngIf="(authService.authStream | async)">
            KES {{ (authService.authStream | async)?.balance }}
        </div>
    </div>
    <div class="add-matches shadow-sm " *ngIf="matches_to_add > 0">
        <div class="add-txt">
            Lets Go! Add {{ matches_to_add }} {{ matches_to_add > 1 ? "matches" : "match" }} and get a BONUS
        </div>
    </div>
    
    <div class="matchessec" style="max-height: 260px; overflow-y: auto;">
        <!-- Begin Match -->
        <div class="match-wrapper" *ngFor="let slip of betslip">
            <div class=" d-flex justify-content-left">
                <div class="rem-sec py-3 w-20 text-center hover-pointer" (click)="removeMatch(slip)">
                    <i class="bi bi-x-lg"></i>
                </div>
                <div class="det-sec w-80">
                    <div class="row1 d-flex justify-content-between">
                        <div class="leff">
                            {{ slip.oddType }}
                        </div>
                        <div class="righh">
                            {{ slip.odd_value }}
                        </div>
                    </div>

                    <div class="match-detsec">
                        {{ slip.matchId }} {{ slip.homeTeam }} <span class="text-muted">v</span>  {{ slip.awayTeam }}
                    </div>
                    <div class="subtype-sec">
                        <span class="text-muted">Pick: </span>  {{ slip.pick_key }}
                    </div>
                </div>
            </div>
        </div>
        <!-- End match -->

    </div>
   

  <button #openShareBetslipModal type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#staticBackdropShareBet" hidden></button>
  
    <div class="mathsec" [class.vert-shadow]="betslip.length > 3" >
        <div class="share text-right hover-pointer " (click)="shareBet()">
            Share betslip 
       </div>
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Total Stake (KES)
            </div>
            <div class="form-sec">
                <form [formGroup]="betslipForm">
                    <input (keyup)="onKey($event)" (change)="somethingChanged()" formControlName="stake" type="number" class="stake-input" placeholder="KES 49" value="49">
                </form>
            </div>
        </div>
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Total Odds
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ toTwoDp(betamount.total_odds) }}</span>
            </div>
        </div>
        <hr>
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Stake after Tax (KES)
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ toTwoDp(betamount.stake_after_tax) }}</span>
            </div>
        </div>

        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Possible Win (KES)
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ toTwoDp(betamount.raw_possible_win) }}</span>
            </div>
        </div>
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                WH Tax 20% (KES)
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ toTwoDp(betamount.withholding_tax) }}</span>
            </div>
        </div>
        <div class="stake-sec d-flex justify-content-between">
            <div class="tittt" >
                Bonus (KES)
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ toTwoDp(betamount.bonus) }}</span>
            </div>
        </div>
        <div class="stake-sec stake-sec-win d-flex justify-content-between">
            <div class="tittt" >
               To Win (KES)
            </div>
            <div class="form-sec">
                <span class="slightly-bold">{{ toTwoDp(betamount.net_possible_win) }}</span>
            </div>
        </div>
    </div>
    <div class="freebet px-2 mb-2" *ngIf=" auth?.free_bet > 20" >
        <button (click)="activateFreebet(auth?.free_bet)" [disabled]="isLoading" class="btn btn-free-bet btn-success btn-sm form-control" style="border-radius: 0px!important;">
             <span> <i class="bi bi-gift"></i> Redeem KES {{(authService.authStream | async)?.free_bet}} Freebet</span> 
        </button>
    </div>
    <div class="submission d-flex justify-content-center" style="width: 100%;">
        <div class="w-50">
            <button class="btn btn-remove-all btn-danger btn-sm form-control" (click)="clearSlip()">
                <i class="bi bi-trash"></i> Remove All
            </button>
        </div>
        <div class="w-50">
            <button (click)="placeBet()" [disabled]="isLoading" class="btn btn-remove-all btn-success btn-sm form-control">
                <i class="bi bi-check2"></i> Place Bet
            </button>
        </div>
    </div>
</section>
