import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'backend';

@Component({
  selector: 'app-registerfinish',
  templateUrl: './registerfinish.component.html',
  styleUrls: ['./registerfinish.component.css']
})
export class RegisterfinishComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef; 
  @ViewChild('openLoginModal') openLoginModal: ElementRef;

  constructor( private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    console.log(this.authService.authMsisdn.value);
    this.authService.authMsisdn.subscribe(value => {
      if(value != null){
        this.finishRegistrationForm.setValue({
          phone: value,
          reset_code: ""
        })
      }
    })
    
  }

  phoneInValidVar = false;
  codeInvalidVar = false;

  next = '';
  phone = '';

  finishRegistrationForm = new FormGroup({
    phone: new FormControl(''),
    reset_code: new FormControl(''),
  });

  phoneValid(phone){
    var pattern1 = /^(?:\+?(?:[1-9]{3})|0)?1([0-9]{8})$/;
    var pattern2 = /^(?:\+?(?:[1-9]{3})|0)?7([0-9]{8})$/;
    if(phone == null || phone == ""){
      this.phoneInValidVar = true;
      return false;
    }
    if( phone.match(pattern1) ){
      this.phoneInValidVar = false;
      return true;
    }
    if( phone.match(pattern2) ){
      this.phoneInValidVar = false;
      return true;
    }
    this.phoneInValidVar = true;
    return false;
  }

  passwordValid(password, password2){
    if(password == "" || password == null){
      this.codeInvalidVar = true;
      return false;
    }else if(password != password2){
      this.codeInvalidVar = true;
      this.errorMessage = "Error: Passwords dont match"
      return false;
    }else{
      this.codeInvalidVar = false;
      return true
    }
  }

  isLoading = false;
  isAuthenticated = false;
  user = null;
  errorMessage = '';
  successMessage = '';

  registerUser(finishRegistrationForm){
    this.errorMessage = '';
    var phone = finishRegistrationForm.value.phone;
    var reset_code = finishRegistrationForm.value.reset_code;

    phone = '254' + phone.substring(phone.length-9, phone.length);
    this.authService.finishReg(phone, reset_code).subscribe(response => {
      console.log(response);
      if(response.res == 0){
        this.isLoading = false;
        this.successMessage = response.description;
        
          return setTimeout( () => { 
            this.openLoginModal.nativeElement.click();
            this.closeModal.nativeElement.click();
            }, 4000 );
      }else{ 
        this.errorMessage = response.description;
        this.codeInvalidVar = true;
        this.isLoading = false;
      }
    },
    err => {
      console.log("Error: " + err.errror)
    })
  }

  onSubmit(){
    if(this.phoneValid(this.finishRegistrationForm.value.phone) ){
      console.log("phone is valid")
      this.isLoading = true;
      this.registerUser(this.finishRegistrationForm);
    }else{
      this.errorMessage = 'Phone number is invalid'
      console.log("phone invalid")
    }
  }

}
