<section class="mega-card-wrapper mega-card-wrapper-gamer">
    <div class="card-wrapper">
        <div class="gamecard-header"  >
            <div class="time">
                {{ getTime(match.start_time) }}
            </div>
            <div class="gameid">
                ID {{ match.game_id }}
            </div>
            <div class="league">
                {{ match.category }} - {{ match.competition_name }}
            </div>
        </div>
        <div class="gamecard-body">
            <div class="match-section" [routerLink]="['/markets']" [queryParams]="{match: match.match_id}">
                <div class="home-team">
                    {{ match.home_team }}
                </div>
                <div class="away-team">
                    {{ match.away_team }}
                </div>
            </div>
            <div class="odd-section odd-section-left" style="">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button [class.picked]="inSlip(match.match_id.toString(), '1', match.home_team)" id="{{ match.match_id + '1' + match.home_team }}" type="button" class="btn btn-odds activ" (click)="actOnButton(match, match.home_team, 1, '1x2', match.home_odd, match.home_odd_id, match.parent_match_id)">{{ match.home_odd }}</button>
                    <button [class.picked]="inSlip(match.match_id.toString(), '1', 'draw')" id="{{ match.match_id + '1' + 'draw' }}" type="button" class="btn btn-odds" *ngIf="currentSport.type == '3way'" (click)="actOnButton(match, 'draw', 1, '1x2', match.neutral_odd, match.draw_odd_id, match.parent_match_id)">{{ match.neutral_odd }}</button>
                    <button [class.picked]="inSlip(match.match_id.toString(), '1', match.away_team)" id="{{ match.match_id + '1' + match.away_team }}" type="button" class="btn btn-odds" (click)="actOnButton(match, match.away_team, 1, '1x2', match.away_odd, match.away_odd_id, match.parent_match_id)">{{ match.away_odd }}</button>
                  </div>
            </div>

            <div class="odd-section odd-section-left hide-mobile" *ngIf="currentSport.type == '3way'" >
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button (click)="actOnButton(match, match.home_team + ' or draw', 10, 'Double chance', match.double_chance_1x_odd, match.dc_1x_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '10', match.home_team + ' or draw')" id="{{ match.match_id + '10' + match.home_team + ' or draw' }}" type="button" class="btn btn-odds ">{{ match.double_chance_1x_odd }}</button>
                    <button (click)="actOnButton(match, 'draw or ' + match.away_team, 10, 'Double chance', match.double_chance_x2_odd, match.dc_x2_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '10', 'draw or ' + match.away_team)" id="{{ match.match_id + '10' + 'draw or ' + match.away_team }}" type="button" class="btn btn-odds">{{ match.double_chance_x2_odd }}</button>
                    <button (click)="actOnButton(match, match.home_team + ' or ' + match.away_team, 10, 'Double chance', match.double_chance_12_odd, match.dc_12_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '10', match.home_team + ' or ' + match.away_team)" id="{{ match.match_id + '10' + match.home_team + ' or ' + match.away_team }}" type="button" class="btn btn-odds">{{ match.double_chance_12_odd }}</button>
                  </div>
            </div>


            <div class="odd-section odd-section-left hide-mobile" *ngIf="currentSport.sport_name == 'Soccer'" >
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button (click)="actOnButton(match, 'over 2.5', 18, 'Total', match.over_25_odd, match.ov25_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '18', 'over 2.5')" id="{{ match.match_id + '18' + 'over 2.5' }}" type="button" class="btn btn-odds ">{{ match.over_25_odd }}</button>
                    <button (click)="actOnButton(match, 'under 2.5', 18, 'Total', match.under_25_odd, match.un25_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '18', 'under 2.5')" id="{{ match.match_id + '18' + 'under 2.5' }}" type="button" class="btn btn-odds">{{ match.under_25_odd }}</button>
                   
                  </div>
            </div>
            <div class="odd-section hide-mobile" *ngIf="currentSport.sport_name == 'Soccer'" >
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button (click)="actOnButton(match, 'yes', 29, 'Both teams to score', match.gg_odd, match.gg_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '29', 'yes')" id="{{ match.match_id + '29' + 'yes' }}" type="button" class="btn btn-odds ">{{ match.gg_odd }}</button>
                    <button (click)="actOnButton(match, 'no', 29, 'Both teams to score', match.ng_odd, match.ng_odd_id, match.parent_match_id)" [class.picked]="inSlip(match.match_id.toString(), '29', 'no')" id="{{ match.match_id + '29' + 'no' }}" type="button" class="btn btn-odds">{{ match.ng_odd }}</button>
                   
                  </div>
            </div>
        </div>
        <div class="gamecard-footer"  [routerLink]="['/markets']"  [queryParams]="{match: match.match_id}">
            + {{ match.side_bets }} Markets
        </div>
    </div>
</section>
