import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'backend';
import {LocalStorageServiceService} from 'backend';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router, private localStorage: LocalStorageServiceService) { }

  ngOnInit(): void {
    this.authService.authStream.subscribe(auth => {
      if (auth === null){
        this.localStorage.clearAuthFromStorage();
        this.router.navigate(['/']).finally();
      }
    });
  }

  logOut(): void {
    this.authService.authStream.next(null);
  }
}
