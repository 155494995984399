import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import {BetslipService, SportsService} from 'backend';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Betslip } from '../../models/Betslip';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  onDestroy: Subject<void> = new Subject<void>();

  constructor(public sportsService: SportsService, private betslipService: BetslipService, private router: Router, private activatedRoute: ActivatedRoute) { }
  

  matchesList: any[] = [];
  dateList: any[] = [];
  @HostListener('window:scroll', ['$event']) 
    doSomething(event) {
      // console.debug("Scroll Event", document.body.scrollTop);
      // see András Szepesházi's comment below
      console.log("Scroll Event", window.pageYOffset );
    }

  ngOnInit(): void {
    
    this.betslipService.activeBetslipStream.next('prematch');
    this.sportsService.matchesStream.subscribe((data) => {
     //console.log(data);

      if (Array.isArray(data)){
        // data.sort((a,b) => this.getIntfromDate(b.start_time) - this.getIntfromDate(a.start_time));
        // console.log(data);
        this.dateList = this.groupByDate(data);
        this.dateList = Object.entries(this.dateList).map(([key, value]) => ({key,value}));
        // console.log();
        
        //  console.log(JSON.stringify(this.dateList));
      }
    });

    this.activatedRoute.queryParams
      .subscribe(params => {
        // console.log(params);
        let bookingCode = params.bookingCode;
        if(bookingCode != undefined){
          this.fetchBookedBetslip(bookingCode);
        }
      }
    );
  }
  // ngOnDestroy(): void {
  //   this.onDestroy.next();
  // }

  fetchBookedBetslip(code){
    this.betslipService.fetchBookedBetslip(code).subscribe(payload => {
      let bslip = JSON.parse(payload);
      if(bslip.length > 0){
        for (let index = 0; index < bslip.length; index++) {
          let match = bslip[index];
          var slip = new Betslip();
          slip.matchId = match.matchId.toString();
          slip.homeTeam = match.homeTeam;
          slip.awayTeam = match.awayTeam;
          slip.betType = 'prematch';
          slip.oddType = match.oddType;
          slip.odd_value = match.odd_value;
          slip.parent_match_id = match.parent_match_id;
          slip.pick_key = match.pick_key;
          slip.sub_type_id = match.sub_type_id;
          slip.event_odd_id = match.event_odd_id;
      
          console.log(slip);
      
          var betslip = this.betslipService.addToBetslip(slip);
          this.betslipService.betslipStream.next(betslip);
          const element = slip[index];
          
        }
      }
      
      console.log(payload);
    })
  }
  
  getPrettyDate(date): string
  {
    const formattedDate = this.getDateWell(date);
    const day = formattedDate.getDay();
    const _date = formattedDate.getDate(); // Date of the month: 2 in our example
    const month = formattedDate.getMonth() + 1; // Month of the Year: 0-based index, so 1 in our example
    const year = formattedDate.getFullYear();

    const weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    return _date + '/' + month + ' ' + weekday[day];

  }

  getDate(date): string {
    const formattedDate = this.getDateWell(date);
    const day = formattedDate.getDate(); // Date of the month: 2 in our example
    const month = (formattedDate.getMonth() + 1); // Month of the Year: 0-based index, so 1 in our example
    const year = formattedDate.getFullYear(); //
    return year + '-' + month + '-' + day;
  }

  getTime(date): string {
    const formattedDate = this.getDateWell(date);
    const hour = formattedDate.getHours(); // Date of the month: 2 in our example
    const minutes = formattedDate.getMinutes();  //
    return hour + ':' + minutes;
  }

  groupByDate(array): any {
    return array.reduce((r, a) => {
          r[this.getDate(a.start_time)] = r[this.getDate(a.start_time)] || [];
          r[this.getDate(a.start_time)].push(a);
          return r;
      }, Object.create(null));
  }
  getDateWell(date_): Date {
    const arr = date_.split(/[- :]/);
    return new Date(Number(arr[0]), Number(arr[1]) - 1, Number(arr[2]), Number(arr[3]), Number(arr[4]), Number(arr[5]));
  }

  getIntfromDate(date_){
    const arr = date_.split(/[- :]/);
    let str = Number(arr[0]).toString() + (Number(arr[1]) - 1).toString() +  Number(arr[2]).toString() + Number(arr[3]).toString() + Number(arr[4]).toString() + Number(arr[5]).toString()
    let num = Number(str);
    return num;
  }

}
