export class Betslip{
    matchId;
    pick_key;
    sub_type_id;
    special_bet_value;
    betType;
    homeTeam;
    awayTeam;
    odd_value;
    oddType; // 1x2, Double chance, Total
    parent_match_id;
    event_odd_id;
    }
    