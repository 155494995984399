<mat-accordion class="desk ">
    <!-- start expansion panel -->
    <mat-expansion-panel [expanded]="sport.sport.sport_name == currentSport?.sport_name" *ngFor="let sport of navData">
      <mat-expansion-panel-header>
        <mat-panel-title class="bigger" style="font-size:15px; font-weight: 500">
            <i  class="bi bi-circle mr-2" style="font-size: 15px;"></i> <span style="padding-left: 10px ;">{{ sport.sport.sport_name }}</span> 
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="league-section " >
        <app-tree [sport]="sport"></app-tree>
       </div>
    </mat-expansion-panel>
     <!-- end expansion panel -->
</mat-accordion>