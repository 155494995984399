import { Component, Input, OnInit } from '@angular/core';
import { BackendService } from 'backend';
import { Betslip } from 'projects/desktop/src/app/models/Betslip';
import { BetslipService } from 'backend';
import { JackpotBetslip } from '../../models/JackpotBetslip';
@Component({
  selector: 'app-gamecardjackpot',
  templateUrl: './gamecardjackpot.component.html',
  styleUrls: ['./gamecardjackpot.component.css']
})
export class GamecardjackpotComponent implements OnInit {

  constructor(private backendService: BackendService, private betslipService: BetslipService) { }
  @Input() match:any;
  @Input() jackpotParams:any;
  ngOnInit(): void {
    this.betslipService.jackpotBetslipStream.subscribe(betslip => {
      this.betslip = betslip;
    })
  }

  getTime(date){
    return this.backendService.getTime(date);
  }

  getDateWell(date_){
    var arr = date_.split(/[- :]/);
    var date = new Date(Number(arr[0]), Number(arr[1])-1, Number(arr[2]), Number(arr[3]), Number(arr[4]), Number(arr[5]));
    return date;
  }

  padString(value){
    return this.backendService.padString(value);
  }

  isActive = true;
  betslip: Array<JackpotBetslip> = [];
  actOnButton(match, pick, position, odd_type, odd_value)
  {
    // console.log("match at gamecard");
    var slip = new JackpotBetslip();
    slip.jp_match_id = match.jp_match_id;
    slip.team1 = match.team1;
    slip.team2 = match.team2;
    slip.betType = 'jackpot';
    slip.oddType = odd_type;
    slip.odd_value = odd_value;
    slip.parent_match_id = match.parent_match_id;
    slip.pick_key = pick;
    slip.position = position;
    slip.combi = match.jp_match_id.toString() + position.toString() + pick.toString();

    // console.log(slip);
    // this.betslip = ;
    // this.store.dispatch(updateBetslip({
    //   betslip:[],
    //   activeBetslip: "jackpot",
    //   jackpotBetslip:this.betService.addToJackpotBetslip(slip)
    // }));
    this.betslip = this.betslipService.addToJackpotBetslip(slip, this.jackpotParams);
    this.betslipService.jackpotBetslipStream.next(this.betslip);
    this.betslipService.activeBetslipStream.next("jackpot");
    this.betslipService.activeJackpotType.next(this.jackpotParams.jp_type);
    
    console.log(this.jackpotParams.jp_type)


    // this.addToBetslipEvent.emit(slip);
  }
  
  inSlip(jp_match_id, position, pick_key){
    if(this.betslip != null){
      let check = this.betslip.find(i => i.jp_match_id === jp_match_id );
      if(check != undefined){
        let _picks = check.pick_key.toString().split(",");;
        for( var m = 0; m < _picks.length; m++){ 
          // this.betslip[i]
          let check = _picks[m];
          if(pick_key == check){
            return true;
          }
        }
      }
    }
    
    return false;
  }

  getPrettyDate(date): string
  {
    const formattedDate = this.getDateWell(date);
    const day = formattedDate.getDay();
    const _date = formattedDate.getDate(); // Date of the month: 2 in our example
    const month = formattedDate.getMonth() + 1; // Month of the Year: 0-based index, so 1 in our example
    const year = formattedDate.getFullYear();

    const weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    return _date + '/' + month + ' ' + weekday[day];

  }


}
