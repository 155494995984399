<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="withdrawalLabel">Withdraw to MPESA</h5>
        <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="error text-danger" *ngIf="errorMessage != ''">
          {{ errorMessage }}
        </div>
        <div class="error text-success" *ngIf="successMessage != ''">
          {{ successMessage }}
        </div>
          <div class="">
              <div class="ball text-muted">
                  Balance (KES) {{ (authService.authStream | async)?.balance }}
              </div>
              <div class="ball ">
                  Withdrawable Balance (KES) {{ (authService.authStream | async)?.balance }}
              </div>
          </div>
          <form [formGroup]="withdrawForm">
              <div class="mb-3 mt-3">
                <input formControlName="amount" type="number" class="form-control" placeholder="Amount to withdraw">
              </div>
              <button type="submit" (click)="onSubmit()" [disabled]="isLoading" class="btn btn-success form-control">Withdraw</button>
            </form>
      </div>
      
    </div>
  </div>