import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BetslipService } from 'backend';
@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>, public betslipService: BetslipService) { }

  ngOnInit(): void {
    this._bottomSheetRef.afterDismissed().subscribe(() => {
      this.betslipService.showtawk();
    });
    this._bottomSheetRef.afterOpened().subscribe(() => {
      this.betslipService.hidetawk();
      console.log("am here")
    })
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  dismissMe():void {
    this._bottomSheetRef.dismiss();

  }

}
