import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import {mainReducer} from './state/main.reducer';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { SportsnavbarComponent } from './components/sportsnavbar/sportsnavbar.component';
import {MatTabsModule} from '@angular/material/tabs';
import { GamecardComponent } from './components/gamecard/gamecard.component';
import { MarketsComponent } from './pages/markets/markets.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatExpansionModule} from '@angular/material/expansion';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AccountComponent } from './pages/account/account.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { BetsComponent } from './pages/bets/bets.component';
import { BetdetailComponent } from './pages/betdetail/betdetail.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { BetslipComponent } from './components/betslip/betslip.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {MatButtonModule} from '@angular/material/button';
import { TreeComponent } from './components/tree/tree.component';
import { DatewrapperComponent } from './components/datewrapper/datewrapper.component';
import { MatDialogModule } from '@angular/material/dialog';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeadermobileComponent } from './components/headermobile/headermobile.component';
import { CountriesComponent } from './pages/countries/countries.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DepositAndPlaceBetComponent } from './dialogs/deposit-and-place-bet/deposit-and-place-bet.component';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { JackpotsComponent } from './pages/jackpots/jackpots.component';
import { JackpotComponent } from './components/jackpot/jackpot.component';
import { GamecardjackpotComponent } from './components/gamecardjackpot/gamecardjackpot.component';
import { BetslipjackpotComponent } from './components/betslipjackpot/betslipjackpot.component';
import {AuthInterceptor} from 'backend';
import { SearchComponent } from './components/search/search.component';
import {NgxShimmerLoadingModule} from 'ngx-shimmer-loading';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import { RegisterfinishComponent } from './components/registerfinish/registerfinish.component';
import { BonusComponent } from './components/bonus/bonus.component';
import { HelpComponent } from './components/help/help.component';
import { VirtualsComponent } from './pages/virtuals/virtuals.component';
import { MobileappComponent } from './components/mobileapp/mobileapp.component';
import { ResponsiblegamblingComponent } from './pages/responsiblegambling/responsiblegambling.component';
import { TermsComponent } from './pages/terms/terms.component';
import { SharebetslipComponent } from './components/sharebetslip/sharebetslip.component';
import { LivecasinoComponent } from './pages/livecasino/livecasino.component';
import { CasinoComponent } from './pages/casino/casino.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    SportsnavbarComponent,
    GamecardComponent,
    MarketsComponent,
    NavbarComponent,
    AccountComponent,
    TransactionsComponent,
    BetsComponent,
    BetdetailComponent,
    BottomSheetComponent,
    BetslipComponent,
    SidebarComponent,
    TreeComponent,
    DatewrapperComponent,
    HeadermobileComponent,
    CountriesComponent,
    LoginComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    DepositComponent,
    WithdrawComponent,
    DialogComponent,
    DepositAndPlaceBetComponent,
    AccountDetailsComponent,
    JackpotsComponent,
    JackpotComponent,
    GamecardjackpotComponent,
    BetslipjackpotComponent,
    SearchComponent,
    ShimmerComponent,
    RegisterfinishComponent,
    BonusComponent,
    HelpComponent,
    VirtualsComponent,
    MobileappComponent,
    ResponsiblegamblingComponent,
    TermsComponent,
    SharebetslipComponent,
    LivecasinoComponent,
    CasinoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatTabsModule,
    MatTreeModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatButtonModule,
    MatDialogModule,
    StoreModule.forRoot({ mainState: mainReducer }),
    NgxShimmerLoadingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
