<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Verify Phone Number</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <button #openLoginModal type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" hidden></button>
      </div>
      <div class="modal-body">
        <div class="text-danger" *ngIf="errorMessage != ''">
          {{ errorMessage }}
        </div>
        <div class="text-danger" *ngIf="successMessage != ''">
            {{ successMessage }}
          </div>
          <form [formGroup]="finishRegistrationForm">
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input formControlName="phone" type="text" [class.is-invalid]="phoneInValidVar" class="form-control" >
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Verification Code</label>
                <input formControlName="reset_code" type="text" [class.is-invalid]="codeInvalidVar" class="form-control">
              </div>
             
              <button (click)="onSubmit()" class="btn btn-success form-control">Verify Code</button>

              
            </form>
      </div>
      
    </div>
  </div>