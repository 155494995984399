import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'backend';
import { Sms } from '../../models/Sms';
import {LocalStorageServiceService} from 'backend';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef
  @ViewChild('openLoginModal') openLoginModal: ElementRef
  @ViewChild('openFinishRegistrationModal') openFinishRegistrationModal: ElementRef

  constructor(private authService: AuthService, private localStorageService: LocalStorageServiceService, private router: Router, public dialog: MatDialog, ) { }
  currentStep: BehaviorSubject<string> = new BehaviorSubject<string>("forgot");
  ngOnInit(): void {
  }

  phoneInValidVar = false;
  passwordInValidVar = false;
  resetCodeInvalidVar = false;

  next = '';


  forgotPasswordForm = new FormGroup({
    phone: new FormControl(''),
    reset_code: new FormControl(''),
    password: new FormControl(''),
  });

  phoneValid(phone){
    var pattern1 = /^(?:\+?(?:[1-9]{3})|0)?1([0-9]{8})$/;
    var pattern2 = /^(?:\+?(?:[1-9]{3})|0)?7([0-9]{8})$/;
    if(phone == null || phone == ""){
      this.phoneInValidVar = true;
      return false;
    }
    if( phone.match(pattern1) ){
      this.phoneInValidVar = false;
      return true;
    }
    if( phone.match(pattern2) ){
      this.phoneInValidVar = false;
      return true;
    }
    this.phoneInValidVar = true;
    return false;
  }

  passwordValid(password){
    if(password == "" || password == null){
      this.passwordInValidVar = true;
      return false;
    }else{
      this.passwordInValidVar = false;
      return true
    }
  }

  getCode(){

  }

  resetPass(){

  }

  register(){

  }

  isLoading = false;
  isAuthenticated = false;
  user = null;
  errorMessage = '';

  openDialog(title, message, status){
    this.dialog.open(DialogComponent, {data: { message: message , title: title, status: status}})

  }
  onSubmit(){
    var phone = this.forgotPasswordForm.value.phone;
    var reset_code = this.forgotPasswordForm.value.reset_code;
    var password = this.forgotPasswordForm.value.password;
    if(this.currentStep.value == 'forgot'){
      if(this.phoneValid(this.forgotPasswordForm.value.phone)){
       phone = "254" + this.forgotPasswordForm.value.phone.substring(this.forgotPasswordForm.value.phone.length-9, this.forgotPasswordForm.value.phone.length);
        this.authService.resetPassword(phone).subscribe(payload => {
          console.log(payload);
          if(payload.res == 0){
            this.forgotPasswordForm.setValue({
              phone: phone,
              reset_code: "",
              password: ""
            });
            this.currentStep.next("reset");
          }else{
            this.closeModal.nativeElement.click();
            this.openDialog("Oops!", payload.description, "error");
          }
        })
      }else{
        this.phoneInValidVar = true;
      }
    }else{
      if(phone != "" && reset_code != "" && password != ""){
        this.authService.confirmReset(phone, reset_code, password).subscribe(response => {
          console.log(response);
          if(response.res == 0){
            this.closeModal.nativeElement.click();
            this.openLoginModal.nativeElement.click();
            this.refresh();
          }else if(response.res == 101){
            this.openFinishRegistrationModal.nativeElement.click();
            this.closeModal.nativeElement.click();
          }else{
            this.closeModal.nativeElement.click();
            this.openDialog("Oops!", response.description, "error");
          }
        })
      }else{
        this.passwordInValidVar = true;
        this.resetCodeInvalidVar = true;
      }
      
    }
   
  }

  refresh(): void {
    window.location.reload();
}

}
