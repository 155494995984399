export class Bet{
    bet_string;
    app_name;
    possible_win;
    profile_id;
    stake_amount;
    stake_after_tax;
    bet_total_odds;
    slip;
    type;
    redeem_free_bet;
}