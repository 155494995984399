import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'backend';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('openLoginModal') openLoginModal: ElementRef
  @ViewChild('openFinishRegistrationModal') openFinishRegistrationModal: ElementRef
  
  ngOnInit(): void {

  }


  phoneInValidVar = false;
  passwordInValidVar = false;

  next = '';

  registerForm = new FormGroup({
    phone: new FormControl(''),
    password: new FormControl(''),
    password2: new FormControl(''),
    medium: new FormControl(''),
  });

  phoneValid(phone){
    var pattern1 = /^(?:\+?(?:[1-9]{3})|0)?1([0-9]{8})$/;
    var pattern2 = /^(?:\+?(?:[1-9]{3})|0)?7([0-9]{8})$/;
    if(phone == null || phone == ""){
      this.phoneInValidVar = true;
      return false;
    }
    if( phone.match(pattern1) ){
      this.phoneInValidVar = false;
      return true;
    }
    if( phone.match(pattern2) ){
      this.phoneInValidVar = false;
      return true;
    }
    this.phoneInValidVar = true;
    this.errorMessage = 'Phone Number is invalid';
    return false;
  }

  passwordValid(password, password2){
    if(password == "" || password == null){
      this.passwordInValidVar = true;
      return false;
    }else if(password != password2){
      this.passwordInValidVar = true;
      this.errorMessage = "Error: Passwords dont match"
      return false;
    }else{
      this.passwordInValidVar = false;
      return true
    }
  }

  isLoading = false;
  isAuthenticated = false;
  user = null;
  errorMessage = '';
  successMessage = '';

  registerUser(loginForm){
    this.errorMessage = '';
    var phone = loginForm.value.phone;
    var password = loginForm.value.password;
    var password2 = loginForm.value.password2;

    phone = '254' + phone.substring(phone.length-9, phone.length);
    this.authService.registerUser(phone, password).subscribe(response => {
      console.log(response);
      if(response.res == 0){
        this.isLoading = false;
        this.successMessage = response.description;

        this.authService.authMsisdn.next(phone);
        this.openFinishRegistrationModal.nativeElement.click();
        this.closeModal.nativeElement.click();
        
        return ;
      }else{
        this.errorMessage = response.description;
        this.phoneInValidVar = true;
        this.passwordInValidVar = true;
        this.isLoading = false;
      }
    },
    err => {
      console.log("Error: " + err.errror)
    })
  }

  login()
  {
    this.openLoginModal.nativeElement.click();
    this.closeModal.nativeElement.click();
  }

  onSubmit(){
    if(this.phoneValid(this.registerForm.value.phone) && this.passwordValid(this.registerForm.value.password, this.registerForm.value.password2)){
      console.log("phone is valid")
      this.isLoading = true;
      this.registerUser(this.registerForm);
    }else{
      
      console.log("phone invalid")
    }
  }


}
