<section class="container-fluid py-2">
    <div class="row-wrapper d-flex justify-content-around">
        <div class="w-15 lef-sec shadow-sm righ-side hide-mobile  bg-light">
           
        </div>
        <div class=" w-65 shadow-sm mid-sec righ-side  bg-light">
            <section id="betdetail-page">
                <div class="topbar d-flex justify-content-left">
                    <div class="icon " [routerLink]="['/bets']">
                        <i class="bi bi-arrow-left text-light"></i>
                    </div>
                    <div class="text-light txt ">
                        Bet Details
                    </div>
                </div>
                <div class="header-sec">
                    <div class="top-row d-flex justify-content-between">
                        <div class="lef">
                            Bet ID: {{ (bet | async)?.our_bet_id }}
                        </div>
                        <div class="righ">
                          {{ getDate((bet | async)?.created) }}
                        </div>
                    </div>
                    <div class="mid-row d-flex justify-content-between">
                        <div class="lef">
                          {{ (bet | async)?.bet_type }}
                        </div>
                        <div class="righ ">
                          {{ (bet | async)?.status === 1 ? 'Active' : (bet | async)?.status === 5 ? 'Won' : 'Lost' }}
                        </div>
                    </div>

                    <div class="last-row d-flex justify-content-between">
                        <div class="lef">
                            Possible Win
                        </div>
                        <div class="righ">
                          {{  (bet | async)?.possible_win }}
                        </div>
                    </div>

                    <div class="mid-last-row mid-last-row-last d-flex justify-content-between">
                        <div class="lef">
                            Total Stake
                        </div>
                        <div class="righ">
                          {{ (bet | async)?.bet_amount }}
                        </div>
                    </div>
                    <div class="mid-last-row  d-flex justify-content-between">
                        <div class="lef">
                            Total Odds
                        </div>
                        <div class="righ">
                          {{ (bet | async)?.total_odd }}
                        </div>
                    </div>
                </div>

                <div class="bets-section" *ngFor="let detail of (betDetails | async)">
                    <div class="game-wrapper d-flex justify-content-left">
                        <div class="status-sec ress" [ngClass]="detail.match_status == 1 ? 'ress-active' : detail.match_status == 5 ? 'ress-won' : ''" style="height: 100%; ">
                          {{ detail.match_status == 1 ? 'Active' : detail.match_status == 5 ? 'Won' : 'Lost' }}
                        </div>
                        <div class="match-details" style="width: 80%;">
                            <div class="game-meta">
                                Game ID: {{ detail.game_id }} | {{ getDate(detail.start_time) }}
                            </div>
                            <div class="teams-sec">
                              {{ detail.home_team }} <span class="text-muted">v</span> {{ detail.away_team }}
                            </div>
                            <div class="ft-score pb-1">
                                <span class="text-muted"> FT Score:</span> {{ detail.ft_score }}
                            </div>

                            <div class="grey-wrap p-1">
                                <div class="first-row d-flex justify-content-left">
                                    <div class="itt w-20">
                                        <span class="text-muted pr-1">Pick: </span>
                                    </div>
                                    <div class="val w-80">
                                      {{ detail.bet_pick }} @ {{ detail.odd_value }}
                                    </div>
                                </div>
                                <div class="first-row d-flex justify-content-left">
                                    <div class="itt w-20">
                                        <span class="text-muted pr-1">Market: </span>
                                    </div>
                                    <div class="val w-80">
                                      {{ detail.bet_type }}
                                    </div>
                                </div>
                                <div class="first-row d-flex justify-content-left">
                                    <div class="itt w-20">
                                        <span class="text-muted pr-1">Outcome: </span>
                                    </div>
                                    <div class="val w-80">
                                      {{ detail.winning_outcome }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="w-20 shadow-sm righ-side hide-mobile bg-light">
            
        </div>
    </div>

</section>
