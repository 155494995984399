<section style="background-color: #F2F3F5; width: 100%; overflow-x: hidden;">
    <div class="head-wrapper">

        <!-- Account details section -->
        <div class="row">
            <div class="col-4 account-card">
                <span class="small-label">Balance</span>  <br>
                <strong class="big-label">KSH {{ (authService.authStream | async)?.balance }}</strong>
            </div>
            <div class="col-4 account-card slight-border-left">
                <span class="small-label">Bonus</span>  <br>
                <strong class="big-label">KSH {{ (authService.authStream | async)?.bonus }}</strong>
            </div>
            <div class="col-4 account-card slight-border-left">
                <span class="small-label">Freebet</span>  <br>
                <strong class="big-label">KSH {{ (authService.authStream | async)?.free_bet }}</strong>
            </div>
        </div>

        <!-- Deposit and withdraw section -->

        <div class="row mt-3">
            <div class="col-6">
                <button data-bs-toggle="modal" data-bs-target="#deposit" class="btn form-control btn-deposit text-light">
                    <i  class="bi bi-cash-coin"></i> Deposit
                </button>
            </div>
            <div class="col-6">
                <button data-bs-toggle="modal" data-bs-target="#withdrawal" class="btn form-control btn-deposit text-light">
                    <i  class="bi bi-wallet"></i> Withdraw
                </button>
            </div>
        </div>
    </div>



    <!-- Withdrawal Modal -->


  <div class="modal " id="withdrawal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="withdrawalLabel" aria-hidden="true">
    <app-withdraw></app-withdraw>
  </div>

  <!-- Deposit modal -->
  <div class="modal " id="deposit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="depositLabel" aria-hidden="true">
    <app-deposit></app-deposit>
  </div>
</section>
