import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.css']
})
export class ShimmerComponent implements OnInit {

  @Input() count: number;
  @Input() type: 'bet' | 'transaction' | 'prematch' | 'jackpot';

  constructor() {
    if (this.count === null || this.count === undefined) {
      this.count = 1;
    }
  }

  ngOnInit(): void {
  }
}
